<template>
  <rv-dialog :title="title" @close="close">
    <el-form
      ref="form"
      :model="shopForm"
      :rules="shopRules"
      label-width="80px"
      :inline="true"
    >
      <el-form-item label="门店名称" prop="shopName">
        <el-input v-model="shopForm.shopName"></el-input>
      </el-form-item>
      <el-form-item label="所属城市" prop="cityId">
        <el-select v-model="shopForm.cityId" placeholder="请选择所属大区">
          <el-option
            v-for="item in cityList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="option-buttons">
      <el-button type="warning" icon="el-icon-plus" @click="addShop"
        >保存</el-button
      >
      <el-button type="primary" @click="close">取消</el-button>
    </div>
  </rv-dialog>
</template>

<script>
import { shopAdd, shopEdit } from "@/api/system/shop";
import { cityQueryList } from "@/api/system/city";
export default {
  data() {
    return {
      dialogState: true,
      title: "新增门店",
      shopForm: {
        shopName: "",
        cityId: "",
      },
      shopRules: {
        shopName: [
          { required: true, message: "请填写门店名称", trigger: "blur" },
        ],
        cityId: [
          { required: true, message: "请选择所属城市", trigger: "change" },
        ],
      },
      cityList: [],
    };
  },
  methods: {
    addShop() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.isUpdate) {
            shopEdit(this.shopForm).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.$emit("close", true);
              }
            });
          } else {
            shopAdd(this.shopForm).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                });
                this.$emit("close", true);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    close() {
      this.$emit("close");
    },
    initData(e) {
      this.isUpdate = true;
      this.title = "修改门店";
      this.shopForm = JSON.parse(JSON.stringify(e));
    },
    getAllArea() {
      cityQueryList({}).then((res) => {
        if (res.code == 0) {
          this.cityList = res.result;
        }
      });
    },
  },
  mounted() {
    this.getAllArea();
  },
};
</script>

<style scoped>
.el-dialog__header {
  background: rgb(26, 36, 62);
  display: flex;
}
.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #fff;
}
.option-buttons {
  display: flex;
  justify-content: center;
}
</style>