<template>
  <div class="user_info">
<!--    <div class="user_name">-->
<!--      <el-image :src="userInfo.userImage" fit="fix" style="width: 200px;height: 200px"/>-->
<!--      <span>{{userInfo.userName}}</span>-->
<!--    </div>-->
    <div class="user_detail">
      <div class="item">
        <div>车辆名称</div>
        <div>{{carInfo.carName}}</div>
      </div>
      <el-divider/>
      <div class="item">
        <div>车牌号</div>
        <div>{{carInfo.carNumber}}</div>
        <div>车架号</div>
        <div>{{carInfo.carVin}}</div>
      </div>
      <el-divider/>
      <div class="item">
        <div>车辆颜色</div>
        <div>{{carInfo.carColour}}</div>
        <div>车辆类型</div>
        <div>{{isCarType(carInfo.carType)}}</div>
      </div>
       <el-divider/>
      <div class="item">
        <div>车辆状态</div>
        <div>{{isCarStatus(carInfo.status)}}</div>
        <div>城市</div>
        <div>{{carInfo.carCity}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import {formatTimeToStr} from '@/api/date'
import {isCarStatus,isCarType} from '@/api/carview'
export default {
  data() {
    return {
      carInfo:{
        userImage: require('@/assets/images/carIcon.png'),
        userName: 'TestUser',
        realName: '张三',
        contactPlace:'沈阳市皇姑区XX街XX路',
        contactPhone: '13900000000',
        joinTime:'2021-04-21 00:00:00',
      }
    }
  },
  props:{
    orderId:String,
    carInfoObj:Object
  },
  mounted() {
    if(this.carInfoObj){
      this.carInfo = this.carInfoObj
    }
  },
  methods:{
    timeFormate(val){
      if(val){
        return formatTimeToStr(val)
      }else{
        return '0000-00-00 00:00:00'
      }
    },
    isCarStatus(v){
      return isCarStatus(v)
    },
    isCarType(v){
      return isCarType(v)
    },
  }
}
</script>
<style scoped>
.user_info{
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  align-items: flex-start;
}
.user_name{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #2c2c2c;
  font-weight: bold;
}
.user_name span{
  margin-top: 10px;
}
.user_detail{
  display: flex;
  flex-direction: column;
  /*margin-left: 20px;*/
  flex: 1;
}
.item{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.item div:nth-child(2n-1){
  color: #8c939d;
  width: 60px;
  text-align: right;
}
.item div:nth-child(2n){
  color: #2c2c2c;
  font-size: 18px;
  margin-left: 20px;
  margin-right: 20px;
  min-width: 200px;
}
</style>