<template>
	<div class="order_info">
		<div class="car_name">
			<!--      <el-image :src="orderInfo.carImage" fit="fix" style="width: 200px;height: auto"/>-->
			<!--      <span>{{orderInfo.orderNumber}}</span> v-model="searchOption.vehicleSn" -->
		</div>


		<div class="order_detail">
			<el-form :model="orderInfo" ref="orderForm" :rules="rules" label-width="180px">
				<div class="item item-f-column" style="margin-top: 20px">
					<div class="item-item mb24">
						<el-form-item label="订单编号" prop="orderNumber" style="margin-bottom: 0">
							<el-input placeholder="订单编号" v-model="orderInfo.orderNumber" readonly :disabled="isCheck"
								class="handle-input"></el-input>
						</el-form-item>
						<el-checkbox :true-label="'1'" :false-label="'0'" v-model="orderInfo.isFocus"
							style="margin-left: 10px" :disabled="isCheck">重点关注</el-checkbox>
						<el-checkbox :true-label="'1'" :false-label="'0'" v-model="orderInfo.isMortgage"
							style="margin-left: 10px" :disabled="isCheck">抵押车辆</el-checkbox>
						<el-checkbox :true-label="'1'" :false-label="'0'" v-model="orderInfo.isControl"
							style="margin-left: 10px" :disabled="isCheck">疫情管控</el-checkbox>
						<el-checkbox :true-label="'1'" :false-label="'0'" v-model="orderInfo.isPriorityReminder"
							style="margin-left: 10px" :disabled="isCheck">优先提醒</el-checkbox>
						<span class="labelGreen" v-if="preorderNoAble">原订单号可用</span>
						<span class="labelRed" v-if="preorderYesAble">原订单号已存在</span>
					</div>
					<div class="item-item">
						<el-form-item label="原订单编号" prop="preNumber" style="margin-bottom: 0">
							<el-input placeholder="原订单编号" v-model="orderInfo.preNumber" class="handle-input"
								:disabled="isCheck" @blur="checkPreNO"></el-input>
						</el-form-item>
						<el-form-item label="订单状态" label-width="80px" prop="status" style="margin-bottom: 0">
							<el-select v-model="orderInfo.status" class="handle-input" :disabled="isCheck"
								placeholder="订单状态" clearable>
								<el-option label="确认取车" value="1"></el-option>
								<el-option label="确认还车" value="2"></el-option>
								<el-option label="订单取消" value="3"></el-option>
							</el-select>
						</el-form-item>
						<el-select class="handle-input" placeholder="" :disabled="isCheck"
							v-model="orderInfo.siteSurveyStatus">
							<el-option label="无需现勘" value="0"> </el-option>
							<el-option label="已现勘" value="1"> </el-option>
						</el-select>
						<el-button type="primary" icon="el-icon-plus" @click="addSurvey()" style="margin-left: 20px"
							v-if="!isCheck">添加现勘</el-button>
					</div>
				</div>
				<el-divider />
				<div class="item">
					<el-form-item label="车牌号" prop="carVo.carNumber" style="margin-bottom: 0">
						<el-input placeholder="车牌号" v-model="orderInfo.carVo.carNumber" :disabled="isCheck" clearable
							class="handle-input"></el-input>

						<el-checkbox :true-label="'1'" :false-label="'0'" v-model="orderInfo.isLockCarnumber"
							:disabled="isCheck" style="margin-left: 10px">锁定</el-checkbox>
						<el-checkbox :true-label="'1'" :false-label="'0'" v-model="orderInfo.isFirstLease"
							:disabled="isCheck" style="margin-left: 10px">首次出租</el-checkbox>
					</el-form-item>

					<el-form-item label="城市" prop="orderCity" style="margin-bottom: 0">
						<el-select class="handle-input" placeholder="城市" v-model="orderInfo.orderCity" allow-create
							:disabled="isCheck" clearable>
							<el-option v-for="item in cities" :key="item.id" :label="item.name" :value="item.name">
								<span style="float: left">{{ item.name }}</span>
								<span style="float: right">{{ item.name }}</span>
							</el-option>
						</el-select>
					</el-form-item>
				</div>
				<el-divider />
				<div class="item">
					<el-form-item label="客户姓名" prop="orderUser" style="margin-bottom: 0">
						<el-input placeholder="客户姓名" v-model="orderInfo.orderUser" :disabled="isCheck"
							class="handle-input"></el-input>
					</el-form-item>
					<el-checkbox :true-label="'1'" :false-label="'0'" v-model="orderInfo.isFirstApplication"
						:disabled="isCheck" style="margin-left: 10px">首次申请</el-checkbox>
					<!-- <el-form-item label="客户电话" label-width="110px" prop="orderTel" style="margin-bottom: 0">
            <el-input placeholder="客户电话" v-model="orderInfo.orderTel" class="handle-input"
              :disabled="isCheck"></el-input>
          </el-form-item> -->
					<el-form-item label="身份信息" label-width="70px" prop="orderTel"
						style="margin-bottom: 0;margin-left: 20px ">
						<el-input placeholder="身份信息" v-model="orderInfo.idCardTopSix" class="handle-input"
							:disabled="isCheck" @change="idCardChange"></el-input>
					</el-form-item>
					<div style="flex-shrink: 0;">{{ orderInfo.areaName }}</div>
				</div>
				<el-divider />
				<div class="item">
					<el-form-item label="订单来源" prop="orderType" style="margin-bottom: 0">
						<el-select v-model="orderInfo.orderType" placeholder="订单来源" clearable :disabled="isCheck"
							class="handle-input">
							<el-option key="1" label="自营" value="1"></el-option>
							<el-option key="2" label="携程" value="2"></el-option>
							<el-option key="3" label="悟空" value="3"></el-option>
							<el-option key="4" label="凹凸" value="4"></el-option>
							<el-option key="5" label="租租" value="5"></el-option>
							<el-option key="8" label="飞猪" value="8"></el-option>
							<el-option key="9" label="南航" value="9"></el-option>
							<el-option key="11" label="哈啰" value="11"></el-option>
							<el-option key="13" label="车生活" value="13"></el-option>
							<el-option key="15" label="滴滴租车" value="15"></el-option>
							<el-option key="99" label="其它" value="99"></el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="订单时间" prop="orderTime" style="margin-bottom: 0">
						<el-date-picker v-model="orderInfo.orderTime" type="datetime" style="width: 200px"
							class="handle-input" clearable :disabled="isCheck" placeholder="选择订单日期时间">
						</el-date-picker>
					</el-form-item>
				</div>
				<el-divider />
				<div class="item">
					<el-form-item label="取车时间" prop="realPickTime" style="margin-bottom: 0">
						<el-date-picker v-model="orderInfo.realPickTime" type="datetime" style="width: 200px"
							class="handle-input" clearable placeholder="选择取车日期时间" :disabled="isCheck">
						</el-date-picker>
					</el-form-item>

					<el-form-item label="计划还车时间" prop="planGiveTime" style="margin-bottom: 0">
						<el-date-picker v-model="orderInfo.planGiveTime" type="datetime" style="width: 200px"
							class="handle-input" clearable placeholder="选择还车日期时间" :disabled="isCheck">
						</el-date-picker>
					</el-form-item>
				</div>
				<el-divider />
				<div class="item">
					<el-form-item label="订单备注" prop="orderUser" style="margin-bottom: 0">
						<el-input :disabled="isCheck" placeholder="订单备注" v-model="orderInfo.remark" class="handle-input"
							size="200" max="200"></el-input>
					</el-form-item>
				</div>
				<el-divider />
				<div class="item btns" style="justify-content: space-between;">
					<div style="width: 240px;">
						<span>订单同步时间:{{ orderInfo.insertTime }}</span>
					</div>
					<div style="display: flex;align-items: center;justify-content: center;">
						<div>
							<el-button type="warning" :disabled="btnDisable" icon="el-icon-plus" @click="submitForm"
								class="ml20 mt10 searchBtn" v-if="!isCheck">保存</el-button>
						</div>
						<div>
							<el-button type="primary" @click="closeDialog()" class="ml20 mt10 searchBtn">取消</el-button>
						</div>
					</div>
					<div style="width: 240px;"></div>
				</div>
			</el-form>
		</div>
		<!--      现勘新增-->
		<el-dialog :visible.sync="surveyAddVisible" v-if="surveyAddVisible" :show-close="false" class="nopadding"
			append-to-body width="60%">
			<div slot="title" class="dialog_header">
				<span>添加现勘</span>
				<i class="el-icon-close" style="font-size: 26px" @click="surveyAddVisible = false" />
			</div>
			<SurveyAdd :orderNumber="orderInfo.orderNumber" @closeWindow="closeWindow" />
		</el-dialog>
	</div>
</template>
<script>
	import orderApi from "@/api/order";
	import fenceApi from "@/api/fence";
	import {
		formatTimeToStr
	} from "../api/date";
	import {
		formatOrderType,
		isOrderFocus,
		isOrderStatus
	} from "../api/orderview";
	import {
		Message
	} from "element-ui";
	import SurveyAdd from "./SurveyAdd.vue";
	export default {
		data() {
			let checkCarNo = (rule, value, callback) => {
				if (!value) {
					return callback(new Error("车牌号不能为空"));
				} else {
					callback();
				}
			};
			return {
				surveyAddVisible: false,
				orderInfo: {
					orderNumber: "",
					preNumber: "",
					orderCity: "",
					orderUser: "",
					orderTel: "",
					orderType: "",
					orderTime: "",
					carVo: {
						carNumber: ""
					},
					realPickTime: new Date(),
					planGiveTime: "",
					isFocus: "0",
					status: "1", //订单状态
					isLockCarnumber: "0", //是否锁定
					isMortgage: "0", //是否抵押车辆
					isControl: "0", //是否疫情管控
					isPriorityReminder: "0", //是否优先提醒
					siteSurveyStatus: "0", //是否勘探
					isFirstLease: "0", //是否首次出租
					isFirstApplication: "0", //是否首次申请
					remark: "",
				},
				btnDisable: false,
				cities: [],
				preorderNoAble: false,
				preorderYesAble: false,
				rules: {
					orderCity: [{
						required: true,
						message: "请选择订单所在城市",
						trigger: "change"
					}, ],
					orderType: [{
						required: true,
						message: "请选择订单类型",
						trigger: "change"
					}, ],
					status: [{
						required: true,
						message: "请选择订单状态",
						trigger: "change"
					}, ],
					"carVo.carNumber": [{
						required: true,
						validator: checkCarNo,
						trigger: "blur"
					}, ],
				},
				checkList3: "1",
			};
		},
		props: {
			orderNumber: String,
			isCheck: Boolean,
		},
		components: {
			SurveyAdd,
		},
		mounted() {
			if (this.orderNumber) {
				let param = {
					sn: this.orderNumber,
				};
				orderApi.getOrderContent(param).then((res) => {
					if (res.code == 0) {
						res.result.carVo = res.result.carVo || {
							carNumber: ""
						};
						this.orderInfo = res.result;
						//   this.orderInfo.carNumber = res.result.carVo.carNumber
						// console.log( this.orderInfo.isFocus)
					}
				});
			}
			fenceApi.findCitys().then((res) => {
				if (res.code == 0) {
					this.cities = res.result;
				}
			});
		},
		created() {
			// this.initData({});
		},
		methods: {
			idCardChange(e) {
				orderApi
					.queryByCode({
						code: e,
					})
					.then((res) => {
						if (res.code == 0 && res.result && res.result.id) {
							this.orderInfo.areaName = res.result.name;
						}
					});
			},
			closeWindow() {
				this.surveyAddVisible = false;
			},
			addSurvey() {
				this.surveyAddVisible = true;
			},
			submitForm() {
				this.$refs["orderForm"].validate((valid) => {
					if (valid) {
						// if (this.orderInfo.orderTel) {
						//   //手机格式
						//   var mobilePhone = /^1[3|4|5|6|7|8|9]\d{9}$/;
						//   //座机格式
						//   var telePhone = /^((0\d{2,3})-)?(\d{7,8})$/;
						//   if (
						//     !mobilePhone.test(this.orderInfo.orderTel) &&
						//     !telePhone.test(this.orderInfo.orderTel)
						//   ) {
						//     this.$message.warning("客户电话格式不正确");
						//     return false;
						//   }
						// }
						this.addnews();
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			checkPreNO() {
				orderApi
					.checkPreOrderNumber(this.orderInfo.preNumber.trim())
					.then((res) => {
						if (res.code == 0) {
							if (res.result > 0) {
								//原订单号已存在
								this.preorderNoAble = false;
								this.preorderYesAble = true;
							} else {
								//原订单号未存在
								this.preorderNoAble = true;
								this.preorderYesAble = false;
							}
						}
					});
			},
			closeDialog() {
				this.$emit("closeWindow");
			},
			updateRemark(orderNumber, remark) {
				let param = {
					orderNumber: orderNumber,
					remark: remark,
				};
				if (remark.length > 100) {
					Message.error({
						message: "备注内容最多100个字符！",
					});
				}
				orderApi.editRemark(param).then((res) => {
					if (res.code == 0) {
						//this.loadData();
						// Message.success({
						//     message: '修改成功',
						// })
					} else {
						Message.error({
							message: res.msg,
						});
					}
				});
				//this.inputFocus = "";
			},
			timeFormate(val) {
				if (val) {
					return formatTimeToStr(val);
				} else {
					return "0000-00-00 00:00:00";
				}
			},
			formatOrderType(v) {
				return formatOrderType(v);
			},
			isStatus(v) {
				return isOrderStatus(v);
			},
			isFocus(v) {
				return isOrderFocus(v);
			},
			setchecked(v) {
				return v == 1 ? true : false;
			},
			addnews() {
				// if(this.orderInfo.orderNumber.length==0){
				//     this.$message.error('订单编号未填入')
				//     return
				// }
				//
				// if(this.orderInfo.orderCity.length==0){
				//     this.$message.error('订单城市未选择!')
				//     return
				// }
				// if(this.orderInfo.orderUser.length==0){
				//     this.$message.error('客户姓名未填写!')
				//     return
				// }
				// if(this.orderInfo.orderTel.length==0){
				//     this.$message.error('客户电话未填写!')
				//     return
				// }
				if (this.orderInfo.orderTime) {
					this.orderInfo.orderTime = this.timeFormate(this.orderInfo.orderTime);
				}
				if (this.orderInfo.planGiveTime) {
					this.orderInfo.planGiveTime = this.timeFormate(
						this.orderInfo.planGiveTime
					);
				}
				if (this.orderInfo.realPickTime) {
					this.orderInfo.realPickTime = this.timeFormate(
						this.orderInfo.realPickTime
					);
				}
				this.orderInfo.carVo.carNumber = this.orderInfo.carVo.carNumber.trim();
				this.btnDisable = true;
				orderApi
					.edit(this.orderInfo)
					.then((res) => {
						if (res.code == "0") {
							//console.log(this.orderInfo);
							if (this.orderInfo.remark != "" && this.orderInfo.remark != null) {
								this.updateRemark(
									this.orderInfo.orderNumber,
									this.orderInfo.remark
								);
							}
							Message.success({
								message: "修改成功",
							});
							this.$emit("closeWindow", true);
						} else {
							Message.error({
								message: res.msg,
							});
						}
					})
					.finally(() => {
						this.btnDisable = false;
					});
			},
		},
	};
</script>
<style scoped>
	.order_info {
		display: flex;
		flex-direction: row;
		flex: 1;
		width: 100%;
		align-items: flex-start;
	}

	.car_name {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: #2c2c2c;
		font-weight: bold;
		/*width:300px;*/
	}

	.car_name span {
		margin-top: 10px;
	}

	.order_detail {
		display: flex;
		flex-direction: column;
		/*margin-left: 20px;*/
		flex: 1;
	}

	.item {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.item-f-column {
		flex-direction: column;
	}

	.item-item {
		display: flex;
		align-items: center;
		width: 100%;
	}

	.mb24 {
		margin-bottom: 24px;
	}

	.item div:nth-child(2n-1) {
		color: #8c939d;
		text-align: right;
	}

	.item div:nth-child(2n) {
		color: #2c2c2c;
		font-size: 18px;
		margin-left: 20px;
		margin-right: 20px;
		min-width: 200px;
	}

	.btns {
		height: 50px;
		width: 100%;
		justify-content: center;
		margin-bottom: 40px;
	}

	.handle-input {
		width: 180px;
	}

	.labelGreen {
		color: #5daf34;
		margin-left: 10px;
	}

	.labelRed {
		color: #e62e00;
		margin-left: 10px;
	}

	.dialog_header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		flex: 1;
		color: #fff;
	}
</style>
