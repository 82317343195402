<template>
  <rv-dialog :title="title" @close="close">
    <el-form
      ref="form"
      :model="userForm"
      :rules="userRules"
      label-width="80px"
      :inline="true"
    >
      <el-form-item label="登录名" prop="username">
        <el-input
          v-model="userForm.username"
          placeholder="请填写用户名"
        ></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="nickName">
        <el-input
          v-model="userForm.nickName"
          placeholder="请填写登录名"
        ></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input
          type="password"
          v-model="userForm.password"
          placeholder="请填写密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input
          v-model="userForm.phone"
          placeholder="请填写手机号"
        ></el-input>
      </el-form-item>
      <el-form-item label="所属角色" prop="roleId">
        <el-select v-model="userForm.roleId" placeholder="请选择所属角色">
          <el-option
            v-for="item in roleList"
            :key="item.roleId"
            :label="item.roleName"
            :value="item.roleId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio v-model="userForm.status" label="1">启用</el-radio>
        <el-radio v-model="userForm.status" label="0">禁用</el-radio>
      </el-form-item>
    </el-form>
    <div class="option-buttons">
      <el-button type="warning" icon="el-icon-plus" @click="addUser"
        >保存</el-button
      >
      <el-button type="primary" @click="close">取消</el-button>
    </div>
  </rv-dialog>
</template>

<script>
import { roleQueryList } from "@/api/system/role";
import { userAdd, userEdit } from "@/api/system/user";
export default {
  data() {
    var validatorPhone = function (rule, value, callback) {
      if (value === "") {
        callback(new Error("手机号不能为空"));
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };
    return {
      dialogState: true,
      isUpdate: false,
      title: "新增用户",
      userForm: {
        username: "",
        nickName: "",
        password: "",
        phone: "",
        roleId: "",
        status: "1",
      },
      userRules: {
        username: [
          { required: true, message: "请填写登录名", trigger: "blur" },
        ],
        nickName: [
          { required: true, message: "请填写姓名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请填写密码", trigger: "blur" }],
        phone: [{ required: true, validator: validatorPhone, trigger: "blur" }],
        roleId: [
          { required: true, message: "请选择所属角色", trigger: "blur" },
        ],
        status: [
          { required: true, message: "请选择所属角色", trigger: "blur" },
        ],
      },
      roleList: [],
    };
  },
  methods: {
    addUser() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.isUpdate) {
            userEdit(this.userForm).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.$emit("close", true);
              }
            });
          } else {
            userAdd(this.userForm).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                });
                this.$emit("close", true);
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    close() {
      this.$emit("close");
    },
    initData(e) {
      this.isUpdate = true;
      this.title = "修改用户";
      this.userForm = JSON.parse(JSON.stringify(e));
      this.userForm.status += "";
      this.userForm.password = "";
    },
    getAllRole() {
      roleQueryList({}).then((res) => {
        if (res.code == 0) {
          this.roleList = res.result;
        }
      });
    },
  },
  mounted() {
    this.getAllRole();
  },
};
</script>

<style scoped>
.option-buttons {
  display: flex;
  justify-content: center;
}
::v-deep .el-select {
  width: 177px;
}
</style>