<template>
  <div class="order_info">
    <div class="car_name">
      <!--      <el-image :src="orderInfo.carImage" fit="fix" style="width: 200px;height: auto"/>-->
      <!--      <span>{{orderInfo.orderNumber}}</span> v-model="searchOption.vehicleSn" -->
    </div>
    <div class="order_detail">
      <el-form :model="orderInfo" ref="orderForm" :rules="rules" label-width="180px">
        <el-divider />
        <div class="item">
          <el-form-item label="车牌号" prop="carNumber" style="margin-bottom: 0">
            <el-input placeholder="车牌号" v-model="orderInfo.carNumber" class="handle-input"></el-input>
          </el-form-item>

          <el-form-item label="城市" prop="orderCity" style="margin-bottom: 0">
            <el-select class="handle-input" placeholder="城市" v-model="orderInfo.orderCity" allow-create clearable>
              <el-option v-for="item in cities" :key="item.id" :label="item.name" :value="item.name">
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right">{{ item.name }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-checkbox :true-label="'1'" :false-label="'0'" v-model="orderInfo.isFocus"
            style="margin-left: 10px">重点关注</el-checkbox>

        </div>
        <!-- <el-divider />
        <div class="item">
          <el-form-item
            label="客户姓名"
            prop="orderUser"
            style="margin-bottom: 0"
          >
            <el-input
              placeholder="客户姓名"
              v-model="orderInfo.orderUser"
              class="handle-input"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="客户电话"
            prop="orderTel"
            style="margin-bottom: 0"
          >
            <el-input
              placeholder="客户电话"
              v-model="orderInfo.orderTel"
              class="handle-input"
            ></el-input>
          </el-form-item>
        </div> -->
        <el-divider />
        <div class="item">
          <el-form-item label="订单来源" prop="orderType" style="margin-bottom: 0">
            <el-select v-model="orderInfo.orderType" placeholder="订单来源" clearable class="handle-input">
              <el-option key="1" label="整备" value="1"></el-option>
              <el-option key="2" label="维保" value="2"></el-option>
              <el-option key="3" label="调车" value="3"></el-option>
              <el-option key="4" label="摆渡车" value="4"></el-option>
              <el-option key="5" label="公务车" value="5"></el-option>
              <el-option key="6" label="加油/补电" value="6"></el-option>
              <el-option key="7" label="车辆清洗" value="7"></el-option>
              <el-option key="8" label="检修" value="8"></el-option>
              <el-option key="9" label="试车" value="9"></el-option>
              <el-option key="10" label="定损" value="10"></el-option>
              <el-option key="11" label="检车" value="11"></el-option>
              <el-option key="12" label="贴膜" value="12"></el-option>
              <el-option key="99" label="其它" value="99"></el-option>
            </el-select>
          </el-form-item>

          <!-- <el-form-item
            label="订单时间"
            prop="orderTime"
            style="margin-bottom: 0"
          >
            <el-date-picker
              v-model="orderInfo.orderTime"
              type="datetime"
              style="width: 200px"
              class="handle-input"
              clearable
              placeholder="选择订单日期时间"
            >
            </el-date-picker>
          </el-form-item> -->
        </div>
        <el-divider />
        <div class="item btns">
          <div>
            <el-button type="warning" :disabled="btnDisable" icon="el-icon-plus" @click="submitForm()"
              class="ml20 mt10 searchBtn">保存</el-button>
          </div>
          <div>
            <el-button type="primary" @click="closeDialog()" class="ml20 mt10 searchBtn">取消</el-button>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import orderApi from "@/api/orderpre";
import fenceApi from "@/api/fence";
import { formatTimeToStr } from "../api/date";
import { formatOrderType, isOrderFocus, isOrderStatus } from "../api/orderview";
import { Message } from "element-ui";
export default {
  data() {
    return {
      orderInfo: {
        orderNumber: "",
        orderCity: "",
        orderUser: "",
        orderTel: "",
        orderType: "",
        orderTime: "",
        carVo: { carNumber: "" },
        carNumber: "",
        isFocus: "0"
      },
      btnDisable: false,
      cities: [],
      rules: {
        carNumber: [
          { required: true, message: "请输入车牌号", trigger: "blur" },
        ],
        orderCity: [
          { required: true, message: "请选择订单所在城市", trigger: "change" },
        ],
        orderType: [
          { required: true, message: "请选择订单来源", trigger: "change" },
        ],
      },
    };
  },
  props: {
    orderId: String,
  },
  mounted() {
    this.$refs["orderForm"].resetFields();
    if (this.orderId) {
      let param = {
        sn: this.orderId,
      };
      orderApi.getOrderContent(param).then((res) => {
        if (res.code == 0) {
          this.orderInfo = res.result;
        }
      });
    }
    fenceApi.findCitys().then((res) => {
      if (res.code == 0) {
        this.cities = res.result;
      }
    });
  },
  created() {
    // this.initData({});
  },
  methods: {
    closeDialog() {
      this.$emit("closeWindow");
    },
    timeFormate(val) {
      if (val) {
        return formatTimeToStr(val);
      } else {
        return "0000-00-00 00:00:00";
      }
    },
    formatOrderType(v) {
      return formatOrderType(v);
    },
    isStatus(v) {
      return isOrderStatus(v);
    },
    isFocus(v) {
      return isOrderFocus(v);
    },
    submitForm() {
      this.$refs["orderForm"].validate((valid) => {
        if (valid) {
          this.addnews();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    addnews() {
      if (this.orderInfo.orderTime) {
        this.orderInfo.orderTime = this.timeFormate(this.orderInfo.orderTime);
      }
      this.orderInfo.carVo.carNumber = this.orderInfo.carNumber.trim();
      this.btnDisable = true;
      orderApi.add(this.orderInfo).then((res) => {
        if (res.code == "0") {
          Message.success({
            message: "添加成功",
          });
        } else {
          Message.error({
            message: res.msg,
          });
        }
        this.btnDisable = false;
      });
    },
  },
};
</script>
<style scoped>
.order_info {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  height: 100%;
  align-items: flex-start;
}

.car_name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #2c2c2c;
  font-weight: bold;
  /*width:300px;*/
}

.car_name span {
  margin-top: 10px;
}

.order_detail {
  display: flex;
  flex-direction: column;
  /*margin-left: 20px;*/
  flex: 1;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item div:nth-child(2n-1) {
  color: #8c939d;

  text-align: right;
}

.item div:nth-child(2n) {
  color: #2c2c2c;
  font-size: 18px;
  margin-left: 20px;
  margin-right: 20px;
  min-width: 200px;
}

.btns {
  height: 50px;
  width: 100%;
  justify-content: center;
  margin-bottom: 40px;
}

.handle-input {
  width: 180px;
}

.labelGreen {
  color: #5daf34;
  margin-left: 10px;
}

.labelRed {
  color: #e62e00;
  margin-left: 10px;
}
</style>
