<template>
  <div class="order_info">
    <div class="car_name">
<!--      <el-image :src="orderInfo.carImage" fit="fix" style="width: 200px;height: auto"/>-->
<!--      <span>{{orderInfo.orderNumber}}</span>-->
    </div>
    <div class="order_detail">
      <div class="item">
        <div>订单编号/原订单编号</div>
        <div>{{orderInfo.orderNumber}}/{{orderInfo.preNumber}}</div>
         <div>城市</div>
        <div>{{orderInfo.orderCity}}</div>
      </div>
      <el-divider/>
            <div class="item">
        <div>客户姓名</div>
        <div>{{orderInfo.orderUser}}</div>
        <div>客户电话</div>
        <div>{{formatTell(orderInfo.orderTel)}}</div>
      </div>
      <el-divider/>
      <div class="item">
         <div>订单来源</div>
        <div>{{formatOrderType(orderInfo.orderType)}}</div>
        <div>订单时间</div>
        <div>{{timeFormate(orderInfo.orderTime)}}</div>
      </div>
      <el-divider/>
      <div class="item">
         <div>取车时间</div>
        <div>{{timeFormate(orderInfo.realPickTime)}}</div>
        <div>计划还车时间</div>
        <div>{{timeFormate(orderInfo.planGiveTime)}}</div>
      </div>
      <el-divider/>
     <div class="item">
         <div>订单状态</div>
        <div>{{isStatus(orderInfo.status)}}</div>
        <div>是否关注</div>
        <div>{{isFocus(orderInfo.isFocus)}}</div>
      </div>

    </div>
  </div>
</template>
<script>
import orderApi from "@/api/order"
import {formatTimeToStr} from '../api/date'
import {formatOrderType,isOrderFocus,isOrderStatus} from '../api/orderview'
export default {
  data() {
    return {
      orderInfo:{
        carImage: require('../assets/images/carIcon.png'),
        carName: 'Murcielago',
        getPlace:'沈阳市皇姑区XX街XX路',
        getTime:'2021-04-21 00:00:00',
        backTime:'2021-04-21 00:00:00',
        deposit: 2000,
        securiy:2000,
      }
    }
  },
  props:{
    orderId:String
  },
  mounted() {
    let param = {
      sn : this.orderId
    }
    orderApi.getOrderContent(param).then(res=>{
      if(res.code == 0){
        this.orderInfo = res.result
      }
    })
  },
  methods:{
    timeFormate(val){
      if(val){
        return formatTimeToStr(val)
      }else{
        return '0000-00-00 00:00:00'
      }
    },
    formatOrderType(v){
      return formatOrderType(v)
    },
    isStatus(v){
      return isOrderStatus(v)
    },
    isFocus(v){
      return isOrderFocus(v)
    },
    formatTell(v){
      if(v){
        let part1 = v.substr(0,3)
        let part2 = v.substr(7)
        return part1+'****'+part2
      }
      return '--'
    }

  }
}
</script>
<style scoped>
.order_info{
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  align-items: flex-start;
}
.car_name{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #2c2c2c;
  font-weight: bold;
  /*width:300px;*/
}
.car_name span{
  margin-top: 10px;
}
.order_detail{
  display: flex;
  flex-direction: column;
  /*margin-left: 20px;*/
  flex: 1;
}
.item{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.item div:nth-child(2n-1){
  color: #8c939d;
  width: 100px;
  text-align: right;
}
.item div:nth-child(2n){
  color: #2c2c2c;
  font-size: 18px;
  margin-left: 20px;
  margin-right: 20px;
  min-width: 200px;
}
</style>
