<template>
  <div ref="page" class="data_wrap">
    <div class="search_cond">
      <el-input v-model="searchOption.equipSn" placeholder="设备编号" class="handle-input mr10 mt10" clearable></el-input>

      <!-- <el-input v-model="searchOption.equipName" placeholder="设备名称"
                class="handle-input mr10 mt10" clearable></el-input> -->

      <el-select v-model="searchOption.factoryName" class="handle-select mr10 mt10" placeholder="厂家" clearable>
        <el-option key="1" label="四海行" value="1"></el-option>
        <el-option key="2" label="艾能" value="2"></el-option>
        <el-option key="3" label="自有硬件" value="3"></el-option>
        <el-option key="4" label="中瑞" value="4"></el-option>
        <el-option key="4" label="赛格" value="5"></el-option>
      </el-select>
      <!-- <el-input v-model="searchOption.factoryName" placeholder="厂家"
                class="handle-input mr10 mt10" clearable></el-input> -->

      <el-select v-if="false" v-model="searchOption.equipType" class="handle-select mr10 mt10" placeholder="设备类型"
        clearable>
        <el-option key="1" label="OBD" value="1"></el-option>
        <el-option key="2" label="无线" value="2"></el-option>
        <el-option key="3" label="有线" value="3"></el-option>
        <el-option key="4" label="断油电" value="4"></el-option>
        <el-option key="5" label="静默" value="5"></el-option>
      </el-select>
      <el-select v-if="false" v-model="searchOption.offline" class="handle-select mr10 mt10" placeholder="设备状态" clearable>
        <el-option key="1" label="在线" value="1"></el-option>
        <el-option key="2" label="离线" value="2"></el-option>
      </el-select>

      <el-button type="primary" icon="el-icon-search" @click="handleSearch()" class="ml20 mt10 searchBtn">搜索</el-button>
    </div>
    <div class="data_list" style="margin-top: 10px">
      <el-table :data="tableData" highlight-current-row :height="contentHeight - 120" v-loading="loading"
        element-loading-text="数据加载中" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)" :header-cell-style="{ background: '#FFF', color: '#212121' }">
        <el-table-column type="index" label="序号" :index="indexMethod" align="left" width="50" />
        <el-table-column label="设备编号" align="left" prop="entityNumber" />
        <!-- <el-table-column label="设备名称" align="left" prop="baiduEntityName"/> -->
        <!-- <el-table-column label="MAC" align="left" prop="entityMac"/> -->
        <el-table-column label="绑定车辆" align="left" min-width="140">
          <template slot-scope="scope">
            <el-button type="text" @click="showCarInfo(scope.row.carVo)">{{
              scope.row.carVo.carNumber
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="厂家" align="left" prop="entityFactory" :formatter="manufactoryFormat" />
        <el-table-column label="设备类型" align="left" :formatter="equipTypeFormat" />
        <el-table-column label="设备状态" align="left" prop="status" :formatter="equipStatusFormat" />
        <el-table-column label="是否在线" align="left" prop="offline" :formatter="equipOfflineFormat" />

        <!--        <el-table-column label="操作" align="left">-->
        <!--          <template slot-scope="scope">-->
        <!--            <el-row>-->
        <!--              <el-col :span="12">-->
        <!--                <el-button type="text" icon="el-icon-open" v-if="scope.row.status == 2" class="btnOnline">在线</el-button>-->
        <!--                <el-button type="text" icon="el-icon-open" v-else class="btnOutLine">离线</el-button>-->
        <!--              </el-col>-->
        <!--              <el-col :span="12">-->
        <!--&lt;!&ndash;                <el-button type="text" icon="el-icon-paperclip">绑定围栏</el-button>&ndash;&gt;-->
        <!--              </el-col>-->
        <!--            </el-row>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
      </el-table>
      <div class="pagination">
        <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next,total"
          :total="searchOption.totalCount" :page-size="searchOption.pageSize">
        </el-pagination>
      </div>
    </div>

    <!--      车辆信息-->
    <el-dialog :visible.sync="carInfoVisible" v-if="carInfoVisible" :show-close="false" width="60%">
      <div slot="title" class="dialog_header">
        <span>车辆信息</span>
        <i class="el-icon-close" style="font-size: 26px" @click="carInfoVisible = false" />
      </div>
      <CarInfo :carInfoObj="carInfo" />
    </el-dialog>
  </div>
</template>
<script>
import equipmentApi from "@/api/equipment";
import CarInfo from "@/components/CarInfo";
export default {
  data() {
    return {
      contentHeight: 0,
      searchOption: {
        equipSn: "",
        equipName: "",
        factoryName: "",
        status: "1",
        equipType: "1",
        pageSize: 10,
        cur_page: 1,
        totalCount: 0,
      },
      tableData: [],
      carInfoVisible: false,
      carInfo: {},
      loading: false
    };
  },
  components: { CarInfo },
  mounted() {
    this.$nextTick(() => {
      this.contentHeight = this.$refs.page.offsetHeight;
    });
    this.loadData();
  },
  methods: {
    handleSearch() {
      this.loadData();
    },
    handleCurrentChange(val) {
      this.searchOption.cur_page = val;
      this.loadData();
    },
    loadData() {
      this.loading = true
      let param = {
        pageNum: this.searchOption.cur_page,
        pageSize: this.searchOption.pageSize,
        equipSn: this.searchOption.equipSn,
        factoryName: this.searchOption.factoryName,
      };
      equipmentApi.getListInMonitor(param).then((res) => {
        if (res.code == 0) {
          this.tableData = res.result.list;
          this.searchOption.totalCount = res.result.total;
        }
      }).finally(() => {
        this.loading = false
      });
    },
    equipStatusFormat(row) {
      //status 设备状态 1-使用中 2-无效
      if (row.status == 1) {
        return (
          <el-tag effect="dark" type="success">
            有效
          </el-tag>
        );
      } else if (row.status == 2) {
        return (
          <el-tag effect="dark" type="info">
            无效
          </el-tag>
        );
      } else {
        return <span>--</span>;
      }
    },
    equipOfflineFormat(row) {
      //offline 是否在线，1：离线，2 在线
      if (row.offline == 2) {
        return (
          <el-tag effect="dark" type="success">
            在线
          </el-tag>
        );
      } else if (row.offline == 1) {
        return (
          <el-tag effect="dark" type="info">
            离线
          </el-tag>
        );
      } else {
        return <span>--</span>;
      }
    },
    equipTypeFormat(row) {
      if (row.entityType == 1) {
        return <span class="etype">OBD</span>;
      } else if (row.entityType == 3) {
        return <span class="etype">有线</span>;
      } else if (row.entityType == 2) {
        return <span class="etype">无线</span>;
      } else if (row.entityType == 4) {
        return <span class="etype">断油电</span>;
      } else if (row.entityType == 5) {
        return <span class="etype">静默</span>;
      } else {
        return <span>--</span>;
      }
    },
    manufactoryFormat(row) {
      if (row.entityFactory == 1) {
        return <span>四海行</span>;
      } else if (row.entityFactory == 2) {
        return <span>艾能</span>;
      } else if (row.entityFactory == 3) {
        return <span>自有硬件</span>;
      } else if (row.entityFactory == 4) {
        return <span>中瑞</span>;
      } else if (row.entityFactory == 5) {
        return <span>赛格</span>
      } else {
        return <span>无</span>;
      }
    },
    indexMethod(index) {
      let curPage = this.searchOption.cur_page;
      let pageSize = this.searchOption.pageSize;
      return index + 1 + (curPage - 1) * pageSize;
    },
    showCarInfo(obj) {
      this.carInfoVisible = true;
      this.carInfo = obj;
    },
  },
};
</script>
<style scoped>
.data_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.handle-input {
  width: 150px;
  display: inline-block;
}

.handle-select {
  width: 120px;
}

.searchBtn {
  height: 30px;
  background: #3b7bcf;
}

.etype {
  font-weight: bold;
  color: #212121;
}

.verticalCol {
  display: flex;
  flex-direction: column;
}

.myDrawer {
  width: 60%;
}

.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #fff;
}

.btnOnline {
  color: #3b7bcf;
}

.btnOutLine {
  color: #8c939d;
}
</style>
<style less>
.el-dialog__header {
  background: rgb(26, 36, 62);
  display: flex;
}
</style>