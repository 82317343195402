<template>
  <div class="chart">
    <div id="warnline" class="myChart" :style="{ width: '100%' }" v-if="option.xAxis.data.length > 0"></div>
    <span v-if="option.xAxis.data.length == 0">暂无数据</span>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  data() {
    return {
      option: {
        tooltip: {              //设置tip提示
          trigger: 'axis'
        },
        legend: {
          data: []
        },
        color: ['#303F9F'],
        xAxis: {                //设置x轴
          type: 'category',
          boundaryGap: true,     //坐标轴两边不留白
          data: [],
          nameTextStyle: {        //坐标轴名称的文字样式
            color: '#303F9F',
            fontSize: 16,
            padding: [0, 0, 0, 20]
          },
          axisLine: {             //坐标轴轴线相关设置。
            lineStyle: {
              color: '#303F9F',
            }
          }
        },
        yAxis: {
          name: '车辆数',
          nameTextStyle: {
            color: '#303F9F',
            fontSize: 16,
            padding: [0, 0, 10, 0]
          },
          axisLine: {
            lineStyle: {
              color: '#303F9F',
            }
          },
          type: 'value'
        },
        series: [
          {
            name: '车辆数',
            data: [],
            type: 'bar',
            smooth: true,
            // symbol: 'none',
          }
        ]
      }
    }
  },
  props: {
    chartData: Array
  },
  components: {
  },
  mounted() {

  },
  methods: {
    drawLine(data) {


      this.option.xAxis.data = data.timeList
      this.option.series[0].data = data.dataList
      this.$nextTick(() => {
        let chartDom = document.getElementById('warnline');
        let myChart = echarts.init(chartDom)
        this.option && myChart.setOption(this.option)
      })
    }
  }
}
</script>
<style scoped>
.chart {
  display: flex;
  width: 100%;
  height: 400px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #000000;
  margin-top: 10px;
}

.myChart {
  width: 100%;
  height: 400px;
}
</style>