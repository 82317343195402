<template>
  <rv-dialog :title="title" @close="close">
    <el-form
      ref="form"
      :model="cityForm"
      :rules="cityRules"
      label-width="80px"
      :inline="true"
    >
      <el-form-item label="城市名称" prop="name">
        <el-input v-model="cityForm.name"></el-input>
      </el-form-item>
      <el-form-item label="所属大区" prop="areaId">
        <el-select v-model="cityForm.areaId" placeholder="请选择所属大区">
          <el-option
            v-for="item in areList"
            :key="item.id"
            :label="item.areaName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="option-buttons">
      <el-button type="warning" icon="el-icon-plus" @click="addCity"
        >保存</el-button
      >
      <el-button type="primary" @click="close">取消</el-button>
    </div>
  </rv-dialog>
</template>

<script>
import { cityAdd, cityEdit } from "@/api/system/city";
import { areaQueryList } from "@/api/system/area";
export default {
  data() {
    return {
      dialogState: true,
      title: "新增城市",
      cityForm: {
        name: "",
        areaId: "",
      },
      cityRules: {
        name: [{ required: true, message: "请填写城市名称", trigger: "blur" }],
        areaId: [
          { required: true, message: "请选择所属大区", trigger: "change" },
        ],
      },
      areList: [],
    };
  },
  methods: {
    addCity() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.isUpdate) {
            cityEdit(this.cityForm).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.$emit("close", true);
              }
            });
          } else {
            cityAdd(this.cityForm).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                });
                this.$emit("close", true);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    close() {
      this.$emit("close");
    },
    initData(e) {
      this.isUpdate = true;
      this.title = "修改城市";
      this.cityForm = JSON.parse(JSON.stringify(e));
    },
    getAllArea() {
      areaQueryList({}).then((res) => {
        if (res.code == 0) {
          this.areList = res.result;
        }
      });
    },
  },
  mounted() {
    this.getAllArea();
  },
};
</script>

<style scoped>
.el-dialog__header {
  background: rgb(26, 36, 62);
  display: flex;
}
.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #fff;
}
.option-buttons {
  display: flex;
  justify-content: center;
}
</style>