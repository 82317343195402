<template>
  <rv-dialog :title="title" @close="close">
    <el-form
      ref="form"
      :model="areaForm"
      :rules="areaRules"
      label-width="80px"
      :inline="true"
    >
      <el-form-item label="区域名称" prop="areaName">
        <el-input v-model="areaForm.areaName"></el-input>
      </el-form-item>
    </el-form>
    <div class="option-buttons">
      <el-button type="warning" icon="el-icon-plus" @click="addArea"
        >保存</el-button
      >
      <el-button type="primary" @click="close">取消</el-button>
    </div>
  </rv-dialog>
</template>

<script>
import { areaAdd, areaEdit } from "@/api/system/area";
export default {
  data() {
    return {
      dialogState: true,
      title: "新增区域",
      isUpdate: false,
      areaForm: {
        areaName: "",
      },
      areaRules: {
        areaName: [
          { required: true, message: "请填写区域名称", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    addArea() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.isUpdate) {
            areaEdit(this.areaForm).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.$emit("close", true);
              }
            });
          } else {
            areaAdd(this.areaForm).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                });
                this.$emit("close", true);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    close() {
      this.$emit("close");
    },
    initData(e) {
      this.isUpdate = true;
      this.title = "修改区域";
      this.areaForm = JSON.parse(JSON.stringify(e));
    },
  },
};
</script>

<style scoped>
.el-dialog__header {
  background: rgb(26, 36, 62);
  display: flex;
}
.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #fff;
}
.option-buttons {
  display: flex;
  justify-content: center;
}
</style>