<template>
  <div ref="page" class="data_wrap">
    <div class="search_cond">
      <el-button
        type="success"
        @click="ExcelEntity()"
        class="cell_btn iconfont icon-daochu"
        >导出订单日志</el-button
      >
    </div>
    <div class="data_list" style="margin-top: 10px">
      <el-table
        ref="table"
        :data="tableData"
        highlight-current-row
        :height="`calc(100vh - 340px)`"
        :header-cell-style="{ background: '#FFF', color: '#212121' }"
        @sort-change="tableSortChange"
      >
        <el-table-column
          type="index"
          label="序号"
          :index="indexMethod"
          align="left"
          width="50"
        />
        <el-table-column label="操作者" align="left" width="70">
          <template slot-scope="scope">
            <span>{{ scope.row.createBy }}{{ scope.row.updateBy }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作时间" align="left" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.createTime }}{{ scope.row.updateTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="数据来源" align="left" width="70">
          <template slot-scope="scope">
            <span
              >{{ isOrderAddMode(scope.row.addMode)
              }}{{ isOrderAddMode(scope.row.updateMode) }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="订单编号/原订单编号" align="left" width="230">
          <template slot-scope="scope">
            <el-button type="text" style="user-select: unset">{{
              scope.row.orderNumber
            }}</el-button>
            <span>/{{ scope.row.preNumber }}</span>
            <div>[{{ formatOrderType(scope.row.orderType) }}]</div>
          </template>
        </el-table-column>
        <el-table-column label="车牌号" align="left" width="100">
          <template slot-scope="scope">
            <el-button type="text">{{ scope.row.carVo.carNumber }}</el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column label="用户姓名" align="left" >
          <template slot-scope="scope">
            <el-button type="text" >{{scope.row.orderUser}}</el-button>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="orderUser"
          label="客户姓名"
          align="left"
          width="80"
        />
        <!-- <el-table-column
          prop="orderTel"
          label="电话"
          align="left"
          width="120"
        /> -->
        <el-table-column
          prop="orderCity"
          label="城市"
          align="left"
          width="80"
        />
        <el-table-column
          prop="orderTime"
          label="下单时间"
          align="left"
          :formatter="formatTime"
          width="160"
        />
        <el-table-column
          prop="realPickTime"
          label="取车时间"
          align="left"
          :formatter="formatTime"
          width="160"
        />
        <el-table-column
          label="预计还车时间"
          prop="planGiveTime"
          align="left"
          sortable="custom"
          width="160"
        >
          <template slot-scope="scope">
            <div class="verticalCol">
              <span>{{ timeToStr(scope.row.planGiveTime) }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="orderMil" label="订单里程" align="left" />
        <el-table-column
          prop="status"
          label="订单状态"
          align="left"
          :formatter="formatOrder"
          show-overflow-tooltip
        />
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          layout="prev, pager, next,total"
          :total="searchOption.totalCount"
          :page-size="searchOption.pageSize"
        >
        </el-pagination>
      </div>
    </div>

    <!--      订单信息-->
    <el-dialog
      :visible.sync="orderInfoVisible"
      v-if="orderInfoVisible"
      :show-close="false"
      width="60%"
    >
      <div slot="title" class="dialog_header">
        <span>订单信息</span>
        <i
          class="el-icon-close"
          style="font-size: 26px"
          @click="orderInfoVisible = false"
        />
      </div>
      <OrderInfo :orderId="selectOrerId" />
    </el-dialog>
    <!--      车辆信息-->
    <el-dialog
      :visible.sync="carInfoVisible"
      v-if="carInfoVisible"
      :show-close="false"
      width="60%"
    >
      <div slot="title" class="dialog_header">
        <span>车辆信息</span>
        <i
          class="el-icon-close"
          style="font-size: 26px"
          @click="carInfoVisible = false"
        />
      </div>
      <CarInfo :carInfoObj="carInfo" />
    </el-dialog>
    <!--      车辆实时位置-->
    <el-dialog
      :visible.sync="realTimePosVisible"
      v-if="realTimePosVisible"
      :show-close="false"
      :fullscreen="fullscreen"
      :class="fullscreen ? 'nopadding dialogFullSc' : 'nopadding dialogLessSc'"
    >
      <div slot="title" class="dialog_header">
        <span>车辆实时位置</span>
        <div>
          <i
            class="iconfont icon-zuixiaohua_huaban1"
            style="font-size: 26px"
            @click="fullscreen = false"
          />
          <i
            class="iconfont icon-zuidahua"
            style="font-size: 26px"
            @click="fullscreen = true"
          />
          <i
            class="el-icon-close"
            style="font-size: 26px"
            @click="realTimePosVisible = false"
          />
        </div>
      </div>
      <VehiclePosition
        :orderNumber="selectOrderNumber"
        :city="selectOrderCity"
      />
    </el-dialog>
    <!--      车辆轨迹-->
    <el-dialog
      :visible.sync="orderTraceVisible"
      v-if="orderTraceVisible"
      :show-close="false"
      class="nopadding"
      :style="'height:' + pageHeight * 0.9 + 'px'"
      width="60%"
    >
      <div slot="title" class="dialog_header">
        <span>车辆轨迹</span>
        <i
          class="el-icon-close"
          style="font-size: 26px"
          @click="orderTraceVisible = false"
        />
      </div>
      <VehicleTrace
        :orderNumber="selectOrderNumber"
        :city="selectOrderCity"
        :startTime="selectOrderStartTime"
      />
    </el-dialog>
    <!--      订单新增-->
    <el-dialog
      :visible.sync="orderAddVisible"
      v-if="orderAddVisible"
      :show-close="false"
      class="nopadding"
      width="60%"
    >
      <div slot="title" class="dialog_header">
        <span>订单新增</span>
        <i
          class="el-icon-close"
          style="font-size: 26px"
          @click="orderAddVisible = false"
        />
      </div>
      <OrderAdd @closeWindow="orderAddVisible = false" />
    </el-dialog>
    <!--      订单修改-->
    <el-dialog
      :visible.sync="orderEditVisible"
      v-if="orderEditVisible"
      :show-close="false"
      class="nopadding"
      :style="'height:' + pageHeight * 0.9 + 'px'"
      width="60%"
    >
      <div slot="title" class="dialog_header">
        <span>订单修改</span>
        <i
          class="el-icon-close"
          style="font-size: 26px"
          @click="orderEditVisible = false"
        />
      </div>
      <OrderEdit
        :orderNumber="selectOrderNumber"
        @closeWindow="orderEditVisible = false"
      />
    </el-dialog>
    <!--      订单合并 -->
    <el-dialog
      :visible.sync="orderMergeVisible"
      v-if="orderMergeVisible"
      :show-close="false"
      class="nopadding"
      :style="'height:' + pageHeight * 0.9 + 'px'"
      width="60%"
    >
      <div slot="title" class="dialog_header">
        <span>订单合并</span>
        <i
          class="el-icon-close"
          style="font-size: 26px"
          @click="orderMergeVisible = false"
        />
      </div>
      <OrderMerge />
    </el-dialog>
    <!--  报警历史列表 -->
    <el-dialog
      :visible.sync="alarmListVisible"
      v-if="alarmListVisible"
      :show-close="false"
      width="60%"
    >
      <div slot="title" class="dialog_header">
        <span>订单备注列表</span>
        <i
          class="el-icon-close"
          style="font-size: 26px"
          @click="alarmListVisible = false"
        />
      </div>
      <OrderRemarkListInfo :orderNum.sync="selectOrderNumber" />
    </el-dialog>
  </div>
</template>
<script>
import orderApi from "@/api/order";
import { formatTimeToStr } from "@/api/date";
import CarInfo from "@/components/CarInfo";
import OrderInfo from "@/components/OrderInfo";
import OrderAdd from "@/components/OrderAdd";
import OrderEdit from "@/components/OrderEdit";
import OrderMerge from "@/components/OrderMerge";
import {
  formatOrderType,
  isOrderStatus,
  isOrderAddMode,
} from "@/api/orderview";
import VehiclePosition from "@/components/VehiclePosition";
import VehicleTrace from "@/components/VehicleTrace";
import monitor from "@/api/monitor";
import { Message } from "element-ui";
import OrderRemarkListInfo from "@/components/OrderRemarkList";
import { export2File } from "@/api/exp2file";
export default {
  data() {
    return {
      contentHeight: 0,
      searchOption: {
        orderNumber: "",
        vehicleSn: "",
        userName: "",
        warnType: "",
        city: "",
        pageSize: 10,
        cur_page: 1,
        totalCount: 0,
        carNumber: "",
        orderType: "",
        status: "",
        orderCity: "",
        sortby: "",
        order: "",
      },
      tableData: [],
      cities: [],
      orderInfoVisible: false,
      realTimePosVisible: false,
      orderTraceVisible: false,
      orderAddVisible: false,
      orderEditVisible: false,
      orderMergeVisible: false,
      pageHeight: 0,
      carInfoVisible: false,
      alarmListVisible: false,
      selectOrderNumber: "",
      selectOrderCity: "",
      inputFocus: "",
      carInfo: {},
      autoOrderMergeTime: 60, //订单合并检测时间秒
      labelTxt: "订单合并(0条)",
      proptype: "", //存放column.prop的字符串值
      mergetimer: null,
    };
  },
  components: {
    CarInfo,
    OrderInfo,
    VehiclePosition,
    VehicleTrace,
    OrderEdit,
    OrderAdd,
    OrderMerge,
    OrderRemarkListInfo,
  },
  beforeDestroy() {
    clearInterval(this.mergetimer);
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.table.doLayout();
    });
  },
  props: {
    orderNum: String,
  },
  mounted() {
    this.loadData();
  },
  methods: {
    handleSearch() {
      this.loadData();
    },
    loadData() {
      let param = {
        pageNum: this.searchOption.cur_page,
        pageSize: this.searchOption.pageSize,
        orderNumber: this.orderNum,
      };
      //console.log(this.searchOption.cur_page);
      orderApi.getListLog(param).then((res) => {
        if (res.code == 0) {
          this.tableData = res.result.list;
          this.searchOption.totalCount = res.result.total;
        }
      });
    },
    ExcelEntity() {
      let param = {
        orderNumber: this.orderNum,
      };
      let myObj = {
        url: process.env.VUE_APP_BASE_API + "/carorderlog/exportOrderLogList",
        fileName: "订单日志列表",
        data: param,
      };
      export2File(myObj);
    },
    tableSortChange(column) {
      this.searchOption.cur_page = 1; //排序后从第一页开始
      if (column.order === "descending") {
        this.searchOption.sortby = column.prop;
        this.searchOption.order = "desc";
      } else {
        this.searchOption.sortby = column.prop;
        this.searchOption.order = "asc";
      }
      this.loadData();
    },
    viewAlarm(id) {
      //订单备注列表
      this.selectOrderNumber = id;
      this.alarmListVisible = true;
    },
    isOrderAddMode(val) {
      return isOrderAddMode(val);
    },
    handleCurrentChange(val) {
      this.searchOption.cur_page = val;
      this.loadData();
    },
    orderAdd() {
      //订单新增
      this.orderAddVisible = true;
    },
    orderEdit(v) {
      //订单修改
      this.selectOrderNumber = v;
      this.orderEditVisible = true;
    },
    orderMerge() {
      //订单合并
      this.orderMergeVisible = true;
    },
    showCarInfo(obj) {
      this.carInfoVisible = true;
      this.carInfo = obj;
    },
    selectOrder(sn) {
      this.selectOrerId = sn;
      this.orderInfoVisible = true;
    },
    autoLoadOrderMerge() {
      let self = this;
      self.mergetimer = setInterval(function() {
        self.loadOrderMergeData();
      }, self.autoOrderMergeTime * 1000);
    },
    updateRemark(row) {
      let param = {
        orderNumber: row.orderNumber,
        remark: row.remark,
      };
      if (row.remark.length > 100) {
        Message.error({
          message: "备注内容最多100个字符！",
        });
      }
      orderApi.editRemark(param).then((res) => {
        if (res.code == 0) {
          this.loadData();
        } else {
          Message.error({
            message: res.msg,
          });
        }
      });
      this.inputFocus = "";
    },
    loadOrderMergeData() {
      monitor.getOrderClashCount().then((res) => {
        if (res.code == 0) {
          this.labelTxt = "订单合并[" + res.result + "]条";
        }
      });
    },
    /**
     * 定位监控的设备
     */
    locationEquipment(v, c) {
      // this.$router.push({path:'/trace',params:{eno:'1111111'}})
      // this.$router.push({path:'/trace',params:{eno:'1111111'}})
      // this.selectOrderNumber = v
      // this.selectOrderCity = c
      // this.realTimePosVisible = true
      // this.fullscreen = true
      //弹出页面效果
      let params = { carNumber: v, city: c, equipNumber: "" };

      const { href } = this.$router.resolve({
        path: "/position",
        query: params,
      });
      // console.log(href)
      window.open(href, "_blank", "toolbar=yes, width=900, height=700");
    },
    /**
     * 订单轨迹
     */
    toTrace(obj) {
      // let type = obj.typeAlarm
      // if(type != 4){
      //   this.$router.push({name:'Trace',params:{equipId:obj.macid, pickTime:obj.realPickTime, factory:obj.entity_factory}})
      // }else{
      this.$router.push({
        path: "/property/trace",
        query: {
          carNumber: obj.carVo.carNumber,
          pickTime: obj.realPickTime,
          factory: "",
        },
      });
      // }
    },
    // eslint-disable-next-line no-unused-vars
    formatTime(t, c, cellValue, index) {
      return formatTimeToStr(cellValue);
    },
    formatOrder(t, c, cellValue) {
      return isOrderStatus(cellValue);
    },
    timeToStr(t) {
      if (t) {
        return formatTimeToStr(t);
      } else {
        return "--";
      }
    },
    indexMethod(index) {
      let curPage = this.searchOption.cur_page;
      let pageSize = this.searchOption.pageSize;
      return index + 1 + (curPage - 1) * pageSize;
    },
    formatOrderType(v) {
      return formatOrderType(v);
    },
  },
};
</script>
<style scoped>
.data_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.handle-input {
  width: 150px;
  display: inline-block;
}
.handle-select {
  width: 120px;
}
.searchBtn {
  height: 30px;
  background: #3b7bcf;
}
.verticalCol {
  display: flex;
  flex-direction: column;
}
.myDrawer {
  width: 60%;
}
.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #fff;
}
.editIcon {
  color: #3b7bcf;
  cursor: pointer;
}
</style>
<style less>
.el-dialog__header {
  background: rgb(26, 36, 62);
  display: flex;
}
.nopadding .el-dialog__body {
  padding: 0px;
}
</style>
