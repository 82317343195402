<template>
  <div ref="page" class="data_wrap">
    <div class="search_cond">
      <el-input v-model="searchOption.orderSn" placeholder="订单编号" class="handle-input mr10 mt10"></el-input>
      <el-button type="primary" icon="el-icon-search" @click="handleSearch()" class="ml20 mt10 searchBtn">搜索</el-button>
    </div>
    <div class="data_list" style="margin-top: 10px">
      <el-table v-loading="loading" element-loading-text="数据加载中" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)" :data="tableData" highlight-current-row
        :height="contentHeight - 120" :header-cell-style="{ background: '#FFF', color: '#212121' }">
        <el-table-column type="index" label="序号" :index="indexMethod" align="left" width="50" />
        <el-table-column label="订单编号" align="left">
          <template slot-scope="scope">
            <el-button type="text" @click="selectOrder(scope.row.orderNumber)" style="user-select: unset">{{
              scope.row.orderNumber
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="车牌号" align="left" min-width="140">
          <template slot-scope="scope">
            <el-button type="text" @click="showCarInfo(scope.row.carVo)">{{
              scope.row.carVo.carNumber
            }}</el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column label="用户姓名" align="left" >
          <template slot-scope="scope">
            <el-button type="text" >{{scope.row.orderUser}}</el-button>
          </template>
        </el-table-column> -->
        <el-table-column prop="orderUser" label="客户姓名" align="left" />
        <!-- <el-table-column prop="orderTel" label="电话" align="left" /> -->
        <el-table-column prop="orderCity" label="城市" align="left" />
        <el-table-column prop="orderTime" label="下单时间" align="left" :formatter="formatTime" />

        <el-table-column label="预计还车时间" align="left">
          <template slot-scope="scope">
            <div class="verticalCol">
              <span>{{ timeToStr(scope.row.planGiveTime) }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="realPickTime" label="取车时间" align="left" :formatter="formatTime" />
        <el-table-column prop="orderMil" label="订单里程" align="left" />
      </el-table>
      <div class="pagination">
        <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next,total"
          :total="searchOption.totalCount" :page-size="searchOption.pageSize">
        </el-pagination>
      </div>
    </div>

    <!--      订单信息-->
    <el-dialog :visible.sync="orderInfoVisible" v-if="orderInfoVisible" :show-close="false" width="60%">
      <div slot="title" class="dialog_header">
        <span>订单信息</span>
        <i class="el-icon-close" style="font-size: 26px" @click="orderInfoVisible = false" />
      </div>
      <OrderInfo :orderId="selectOrerId" />
    </el-dialog>
    <!--      车辆信息-->
    <el-dialog :visible.sync="carInfoVisible" v-if="carInfoVisible" :show-close="false" width="60%">
      <div slot="title" class="dialog_header">
        <span>车辆信息</span>
        <i class="el-icon-close" style="font-size: 26px" @click="carInfoVisible = false" />
      </div>
      <CarInfo :carInfoObj="carInfo" />
    </el-dialog>
  </div>
</template>
<script>
import orderApi from "@/api/order";
import { formatTimeToStr } from "@/api/date";
import CarInfo from "@/components/CarInfo.vue";
import OrderInfo from "@/components/OrderInfo";
import { formatOrderType } from "@/api/orderview";
export default {
  data() {
    return {
      contentHeight: 0,
      searchOption: {
        orderSn: "",
        vehicleSn: "",
        userName: "",
        warnType: "",
        city: "",
        pageSize: 10,
        cur_page: 1,
        totalCount: 0,
      },
      tableData: [],
      orderInfoVisible: false,
      pageHeight: 0,
      carInfoVisible: false,
      carInfo: {},
      loading: false
    };
  },
  components: { CarInfo, OrderInfo },
  mounted() {
    this.$nextTick(() => {
      this.contentHeight = this.$refs.page.offsetHeight;
      this.pageHeight = document.documentElement.clientHeight;
      this.loadData();
    });
  },
  methods: {
    loadData() {
      this.loading = true
      let param = {
        pageNum: this.searchOption.cur_page,
        pageSize: this.searchOption.pageSize,
        orderSn: this.searchOption.orderSn,
      };
      orderApi.getListInMonitor(param).then((res) => {
        if (res.code == 0) {
          this.tableData = res.result.list;
          this.searchOption.totalCount = res.result.total;
        }
      }).finally(() => {
        this.loading = false
      });
    },
    handleSearch() {
      this.loadData();
    },
    handleCurrentChange(val) {
      this.searchOption.cur_page = val;
      this.loadData();
    },

    showCarInfo(obj) {
      this.carInfoVisible = true;
      this.carInfo = obj;
    },
    selectOrder(sn) {
      this.selectOrerId = sn;
      this.orderInfoVisible = true;
    },
    /**
     * 定位监控的设备
     */
    locationEquipment() {
      // this.$router.push({path:'/trace',params:{eno:'1111111'}})
      this.realTimePosVisible = true;
    },
    /**
     * 订单轨迹
     */
    toTrace() {
      this.orderTraceVisible = true;
    },
    // eslint-disable-next-line no-unused-vars
    formatTime(t, c, cellValue, index) {
      return formatTimeToStr(cellValue);
    },
    timeToStr(t) {
      if (t) {
        return formatTimeToStr(t);
      } else {
        return "--";
      }
    },
    indexMethod(index) {
      let curPage = this.searchOption.cur_page;
      let pageSize = this.searchOption.pageSize;
      return index + 1 + (curPage - 1) * pageSize;
    },
    formatOrderType(v) {
      return formatOrderType(v);
    },
  },
};
</script>
<style scoped>
.data_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.handle-input {
  width: 150px;
  display: inline-block;
}

.handle-select {
  width: 120px;
}

.searchBtn {
  height: 30px;
  background: #3b7bcf;
}

.verticalCol {
  display: flex;
  flex-direction: column;
}

.myDrawer {
  width: 60%;
}

.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #fff;
}
</style>
<style less>
.el-dialog__header {
  background: rgb(26, 36, 62);
  display: flex;
}

.nopadding .el-dialog__body {
  padding: 0px;
}
</style>