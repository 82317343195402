<template>
  <div class="trace_wrap">
    <div class="infoBoard">
      <div class="condion" ref="content">
        <div class="cell">
          搜索:
          <el-autocomplete
            v-model="enoLabel"
            style="width: 300px"
            clearable
            :fetch-suggestions="querySearchAsync"
            @select="handleSelect"
            placeholder="车牌号或设备号"
            :disabled="playOption == 1"
          >
            <template slot-scope="{ item }">
              <div class="search_result">
                <div class="name">车牌号：{{ item.info.carNumber }}</div>
                <span class="addr">{{
                  "设备：" +
                  item.info.entityNumber +
                  " 类型：" +
                  formatEquipType(item.info.entityType) +
                  " 厂商：" +
                  formatFactory(item.info.entityFactory)
                }}</span>
              </div>
            </template>
          </el-autocomplete>
        </div>
        <div class="cell" style="margin-left: 20px; margin-right: 20px">
          时间：<el-date-picker
            v-model="dateRange"
            :disabled="playOption == 1"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>

        <div class="cell">
          <el-select
            v-model="selectFenceCity"
            placeholder="请选择围栏城市"
            style="width: 160px; margin-right: 10px"
            @change="selectCityChange"
            clearable
          >
            <el-option
              v-for="item in fenceCity"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <el-checkbox v-model="fenceVisible">显示围栏</el-checkbox>
          <el-button
            class="cell_btn"
            icon="el-icon-search"
            @click="search"
            style="margin-left: 10px"
            >搜索</el-button
          >
          <el-button
            class="cell_btn iconfont icon-daochu"
            @click="exportExcel"
            style="margin-left: 10px"
            >导出轨迹</el-button
          >
        </div>
      </div>
      <!--        <div class="bar" @click="collapseChage">-->
      <!--          <i class="el-icon-caret-top"  v-if="extendBar"></i>-->
      <!--          <i class="el-icon-caret-bottom" v-else></i>-->
      <!--        </div>-->
    </div>

    <div class="map_wrap" ref="map">
      <baidu-map class="map" :center="center" :zoom="zoom" @ready="handler">
        <bm-marker
          :position="carPosInfo.pathPos"
          :icon="icon"
          :rotation="carPosInfo.direction"
          v-if="carPosInfo"
          ref="marker"
        />
        <bm-polyline
          :path="posPath"
          stroke-color="blue"
          :stroke-opacity="1"
          :stroke-weight="4"
          :editing="false"
        />

        <bm-polygon
          :path="path"
          v-for="(path, $index) in polygonLayerList"
          :key="$index"
          fillColor="rgb(12,21,46)"
          stroke-color="blue"
          :stroke-opacity="0.5"
          :stroke-weight="2"
        />

        <bm-label
          :content="pos.name"
          v-for="(pos, $index) in labelPos"
          :key="$index + '_' + pos"
          :position="{ lng: pos.lng, lat: pos.lat }"
          :labelStyle="{
            color: 'white',
            fontSize: '18px',
            background: 'black',
            border: '',
          }"
        />

        <bm-control
          :offset="{ width: mapWidth / 2 - 250, height: mapheight - 60 }"
          v-if="mapInstance"
        >
          <div class="trace_control_panel">
            <div class="title1">轨迹播放</div>
            <el-button
              :class="'icon-play iconfont btn1'"
              circle
              @click="playOption = 1"
            ></el-button>
            <el-button
              :class="'icon-pause iconfont btn2'"
              circle
              @click="playOption = 2"
            ></el-button>
            <el-button
              :class="'icon-stop iconfont btn2'"
              circle
              @click="playOption = 3"
            ></el-button>
            <div v-if="playtime > 0" style="margin-left: 20px">
              时间：{{ timeFormate(playtime) }}
            </div>
            <div v-else style="margin-left: 20px">时间：--:--:--</div>
            <div
              style="
                margin-left: 40px;
                display: flex;
                flex-direction: row;
                align-items: center;
              "
            >
              速度：<el-slider
                style="width: 200px; margin-left: 10px; margin-right: 10px"
                v-model="speed"
                :step="10"
              >
              </el-slider>
            </div>
          </div>
        </bm-control>

        <bm-geolocation
          anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
          :showAddressBar="true"
          :autoLocation="true"
        />
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" />

        <bm-info-window
          :position="pointWindowPos"
          title=""
          :show="pointInfoWindow"
          @close="pointInfoWindow = false"
          :offset="{ width: 0, height: -14 }"
          style="width: 240px"
        >
          <p class="w_desc">
            {{ pointDetail.pos }}
          </p>
          <div class="w_item">
            <span>车牌号：</span>
            <span>{{ pointDetail.carNo }}</span>
          </div>
          <div class="w_item">
            <span>车辆名称：</span>
            <span>{{ pointDetail.carName }}</span>
          </div>
          <div class="w_item">
            <span>设备号：</span>
            <span>{{ pointDetail.equipNo }}</span>
          </div>
          <div class="w_item">
            <span>设备类型：</span>
            <span>{{ pointDetail.equipType }}</span>
          </div>
          <div class="w_item">
            <span>定位时间：</span>
            <span>{{ pointDetail.time }}</span>
          </div>
          <div class="w_item">
            <span>当前速度：</span>
            <span>{{ pointDetail.speed }}km/h</span>
          </div>
        </bm-info-window>

        <bm-point-collection
          :points="tracePoints"
          color="blue"
          size="BMAP_POINT_SIZE_SMALL"
        ></bm-point-collection>
      </baidu-map>
    </div>
  </div>
</template>
<script>
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import traceApi from "@/api/trace";
import equipmentApi from "@/api/equipment";
import fenceApi from "@/api/fence";
import { formatTimeToStr } from "@/api/date";
import { export2File } from "@/api/exp2file";
import { Loading } from "element-ui";
import { formatFactory, equipTypeFormat } from "@/api/carview";
export default {
  data() {
    return {
      eno: "",
      enoLabel: "",
      extendBar: true,
      speed: 1,
      dateRange: [],
      center: { lng: 0, lat: 0 },
      icon: {
        url: require("@/assets/images/car.png"),
        size: { width: 52, height: 26 },
        opts: { anchor: { width: 27, height: 13 } },
      },
      zoom: 15,
      posPath: [],
      carPosInfo: null,
      carPath: [],
      playTrace: false,
      playtime: 0,
      isPause: false,
      playOption: 3,
      totalFrame: 0,
      fenceList: [
        { id: 1, name: "围栏1" },
        { id: 2, name: "围栏2" },
      ],
      activeFence: "",
      remoteSearch: false,
      searchResult: [],
      fenceCity: [],
      selectFenceCity: "",
      fenceVisible: false,
      mapInstance: null,
      circleLayerList: [],
      polygonLayerList: [],
      labelPos: [],
      mapheight: 100,
      mapWidth: 100,
      pointWindowPos: {},
      pointInfoWindow: false,
      tracePoints: [],
      pointDetail: {},
      searchTimer: null,
      locationLoading: null,
      defaultCenter: {},
    };
  },
  components: {
    BaiduMap,
  },
  props: {
    equipId: String,
    pickTime: String,
    factory: String,
    carNumber: String,
  },
  watch: {
    playOption(nv) {
      if (nv == 1) {
        this.isPause = false;
        this.play();
      } else if (nv == 2) {
        this.isPause = true;
      } else if (nv == 3) {
        this.isPause = true;
      }
    },
    fenceVisible(nv) {
      if (nv) {
        this.getFenceList(this.mapInstance._bmap, this.mapInstance._map);
      } else {
        for (let i = 0; i < this.circleLayerList.length; i++) {
          this.mapInstance._map.removeOverlay(this.circleLayerList[i]);
        }
        this.circleLayerList = [];
        this.polygonLayerList = [];
        this.labelPos = [];
      }
    },
  },
  mounted() {
    this.mapheight = this.$refs.map.offsetHeight;
    this.mapWidth = this.$refs.map.offsetWidth;
    fenceApi.findCitys().then((res) => {
      if (res.code == 0) this.fenceCity = res.result;
    });
    let equipId = this.$route.params.equipId || this.equipId;
    let selectOrderStartTime = this.$route.params.pickTime || this.pickTime;
    let carNumber = this.$route.params.carNumber || this.carNumber;
    let factory = this.$route.params.factory || this.factory;
    let searchType = "";
    let searchKey = "";
    if (carNumber) {
      searchKey = carNumber;
      searchType = 1;
    }

    if (equipId) {
      searchKey = equipId;
      searchType = 2;
    }
    if (searchKey) {
      equipmentApi.searchCarNo(searchKey).then((res) => {
        if (res.code == 0) {
          let equipList = res.result;

          if (equipList.length > 0) {
            for (let i = 0; i < equipList.length; i++) {
              if (searchType == 1) {
                //车牌
                if (factory.length == 0) {
                  //缺少entityfactory字段
                  if (equipList[i].carNumber == searchKey) {
                    this.enoLabel =
                      equipList[i].carNumber +
                      " | " +
                      equipList[i].entityNumber +
                      " | " +
                      this.formatEquipType(equipList[i].entityType);
                    this.eno =
                      equipList[i].carNumber +
                      " | " +
                      equipList[i].entityNumber +
                      " | " +
                      equipList[i].entityFactory;

                    this.dateRange.push(selectOrderStartTime);
                    this.dateRange.push(new Date());
                    break;
                  }
                } else {
                  if (
                    equipList[i].carNumber == searchKey &&
                    factory == equipList[i].entityFactory
                  ) {
                    this.enoLabel =
                      equipList[i].carNumber +
                      " | " +
                      equipList[i].entityNumber +
                      " | " +
                      this.formatEquipType(equipList[i].entityType);
                    this.eno =
                      equipList[i].carNumber +
                      " | " +
                      equipList[i].entityNumber +
                      " | " +
                      equipList[i].entityFactory;

                    this.dateRange.push(selectOrderStartTime);
                    this.dateRange.push(new Date());
                    break;
                  }
                }
              } else if (searchType == 2) {
                //设备
                if (
                  equipList[i].entityNumber == searchKey &&
                  factory == equipList[i].entityFactory
                ) {
                  this.enoLabel =
                    equipList[i].carNumber +
                    " | " +
                    equipList[i].entityNumber +
                    " | " +
                    this.formatEquipType(equipList[i].entityType);
                  this.eno =
                    equipList[i].carNumber +
                    " | " +
                    equipList[i].entityNumber +
                    " | " +
                    equipList[i].entityFactory;

                  this.dateRange.push(selectOrderStartTime);
                  this.dateRange.push(new Date());
                  break;
                }
              }
            }

            this.search();
          } else {
            this.$message.error("未搜索到轨迹");
          }
        }
        this.remoteSearch = false;
      });
    }
  },
  methods: {
    handler({ BMap, map }) {
      // let mapStyle={style:'grayscale'}
      // map.setMapStyle(mapStyle)
      map.enableScrollWheelZoom();
      // this.zoom = 15
      let self = this;
      if (BMap) {
        const geolocation = new BMap.Geolocation();
        geolocation.getCurrentPosition((res) => {
          const { lng, lat } = res.point;
          self.defaultCenter = { lng, lat };
          self.center = { lng, lat };
        });
        self.mapInstance = { _bmap: BMap, _map: map };
      }
    },
    back() {
      this.$router.back();
    },
    async search() {
      let startTime = "";
      let endTime = "";
      this.locationLoading = Loading.service({
        text: "加载车辆轨迹",
        background: "rgba(0, 0, 0, 0.8)",
      });
      if (this.dateRange.length > 0) {
        let s1 = new Date(this.dateRange[0]).getTime();
        let s2 = new Date(this.dateRange[1]).getTime();
        startTime = s1;
        endTime = s2;
      }
      let no = this.eno.split("|");

      // if(no.length!=4){
      //   this.$message.error('未找到查询设备号')
      //   this.playOption = 3
      //   return
      // }
      let param = {
        entityId: no[1].trim(),
        startTime: Number(startTime),
        endTime: Number(endTime),
        entityFactory: no[2].trim(),
      };
      if (this.eno == "") {
        this.$message.error("还未填写查询设备号");
        this.locationLoading.close();
        return;
      }
      if (startTime == "") {
        this.$message.error("还未填写查询开始日期");
        this.locationLoading.close();
        return;
      }
      if (endTime == "") {
        this.$message.error("还未填写查询结束日期");
        this.locationLoading.close();
        return;
      }
      //2021-05-17 14:52:31   2021-05-20 23:03:29   16820122475
      let self = this;
      await traceApi.loadHisTrace(param).then((res) => {
        if (res.code == 0) {
          let trace = res.result;
          self.tracePoints = [];
          self.posPath = [];
          self.carPath = [];
          self.playtime = 0;
          for (let i = 0; i < trace.length; i++) {
            let pos = {
              lng: trace[i].longitude + "",
              lat: trace[i].latitude + "",
            };
            self.posPath.push(pos);
            self.carPath.push({
              pathPos: pos,
              direction: trace[i].d,
              time: trace[i].lt,
              speed: trace[i].s,
            });
            let tp = {
              lng: trace[i].longitude + "",
              lat: trace[i].latitude + "",
              info: trace[i],
            };
            self.tracePoints.push(tp);
          }
          if (self.posPath.length > 0) {
            self.center = {
              lng: self.posPath[0].lng,
              lat: self.posPath[0].lat,
            };
          } else {
            self.center = self.defaultCenter;
          }
          self.totalFrame = self.posPath.length;
        } else {
          this.$message.error(res.msg);
          self.center = self.defaultCenter;
        }
        this.locationLoading.close();
      });
    },
    play() {
      let self = this;
      if (self.posPath.length > 0) {
        if (self.playtime == 0) {
          self.center = { lng: self.posPath[0].lng, lat: self.posPath[0].lat };
          self.playtime = self.carPath[0].time;
        }
        self.pointInfoWindow = true;
        self.clickTracePointHandler(self.carPath[0]);
        self.isPause = false;
        self.playCartrace();
      } else {
        this.$message.error("当前可播放轨迹空");
      }
    },
    playCartrace() {
      setTimeout(() => {
        if (!this.isPause) {
          if (this.playtime < this.carPath[this.carPath.length - 1].time) {
            if (this.speed == 0) {
              this.speed = 1;
            }

            this.playtime += this.speed * 1000;
            for (let i = 0; i < this.carPath.length; i++) {
              if (this.carPath[i].time > this.playtime) {
                this.carPosInfo = this.carPath[i];
                // this.center = this.carPosInfo.pathPos
                break;
              }
            }

            this.clickTracePointHandler(this.carPosInfo);
            this.pointInfoWindow = true;
            this.pointWindowPos = {
              lat: this.carPosInfo.pathPos.lat,
              lng: this.carPosInfo.pathPos.lng,
            };

            this.playCartrace();
          } else {
            this.playOption = 3;
            this.isPause = false;
            this.pointInfoWindow = false;
            this.playtime = 0;
          }
        }
      }, 100);
    },

    exportExcel() {
      let startTime = "";
      let endTime = "";

      if (this.dateRange.length > 0) {
        let s1 = new Date(this.dateRange[0]).getTime();
        let s2 = new Date(this.dateRange[1]).getTime();
        startTime = s1;
        endTime = s2;
      }
      let no = this.eno.split("|");
      if (no.length < 1) {
        this.$message.error("未找到查询设备号");
        return;
      }
      let param = {
        entityId: no[1].trim(),
        startTime: Number(startTime),
        endTime: Number(endTime),
        entityFactory: no[2].trim(),
      };
      if (this.eno == "") {
        this.$message.error("还未填写查询设备号");
        return;
      }
      if (startTime == "") {
        this.$message.error("还未填写查询开始日期");
        return;
      }
      if (endTime == "") {
        this.$message.error("还未填写查询结束日期");
        return;
      }
      //2021-05-17 14:52:31   2021-05-20 23:03:29   16820122475

      let myObj = {
        url: process.env.VUE_APP_BASE_API + "/localLoc/exportLocLog",
        fileName: "历史轨迹_" + this.timeFormate(startTime),
        data: param,
      };
      export2File(myObj);
    },
    timeFormate(val) {
      return formatTimeToStr(val);
    },
    querySearchAsync(key, callback) {
      if (key != "") {
        let self = this;
        clearTimeout(self.searchTimer);
        self.searchTimer = setTimeout(() => {
          equipmentApi.searchCarNo(key).then((res) => {
            if (res.code == 0) {
              this.searchResult = res.result;
              let searchResultLabel = [];
              for (let i = 0; i < res.result.length; i++) {
                let opt = res.result[i];
                // let label = opt.carNumber+' | '+opt.entityNumber+' | '+self.formatEquipType(opt.entityType)
                // let value = opt.carNumber //+' | '+opt.entityNumber+' | '+opt.entityFactory+' | '+opt.entityType
                searchResultLabel.push({ value: opt.carNumber, info: opt });
              }
              callback(searchResultLabel);
            }
            this.remoteSearch = false;
          });
        }, 1000);
      }
    },
    handleSelect(item) {
      this.enoLabel =
        item.info.carNumber +
        " | " +
        item.info.entityNumber +
        " | " +
        this.formatEquipType(item.info.entityType) +
        " | " +
        this.formatFactory(item.info.entityFactory);
      this.eno =
        item.info.carNumber +
        " | " +
        item.info.entityNumber +
        " | " +
        item.info.entityFactory +
        " | " +
        item.info.entityType;
    },
    formatEquipType(type) {
      if (type == 1) {
        return "OBD";
      } else if (type == 2) {
        return "无线";
      } else if (type == 3) {
        return "有线";
      } else {
        return "--";
      }
    },
    formatFactory(type) {
      return formatFactory(type);
    },
    formateEquipFactory(v) {
      return formatFactory(v);
    },
    formateEquipType(v) {
      return equipTypeFormat(v);
    },
    selectCityChange() {
      if (this.fenceVisible) {
        this.polygonLayerList = [];
        this.getFenceList(this.mapInstance._bmap, this.mapInstance._map);
      }
    },
    getFenceList(BMap, map) {
      let param = {};
      if (this.selectFenceCity) {
        param.city = this.selectFenceCity;
      }
      this.labelPos = [];
      fenceApi.findByCity(param).then((res) => {
        if (res.code == 0) {
          this.fenceList = res.result;
          for (let i = 0; i < this.fenceList.length; i++) {
            if (this.fenceList[i].shape == 1) {
              let circleLayer = new BMap.Circle(
                {
                  lng: this.fenceList[i].longitude,
                  lat: this.fenceList[i].latitude,
                },
                this.fenceList[i].radius,
                {
                  strokeColor: "blue",
                  strokeWeight: 0.5,
                  strokeOpacity: 0.85,
                  fillColor: "rgb(12,21,46)",
                }
              );
              map.addOverlay(circleLayer);
              this.circleLayerList.push(circleLayer);
              this.labelPos.push({
                lng: this.fenceList[i].longitude,
                lat: this.fenceList[i].latitude,
                name: this.fenceList[i].fenceName,
              });
            }
            if (this.fenceList[i].shape == 2) {
              let vertexArrary = this.fenceList[i].vertexes.split(";");
              let posArrary = [];
              let _x = 0,
                _y = 0;

              for (let j = 0; j < vertexArrary.length; j++) {
                let p = vertexArrary[j].split(",");
                posArrary.push({ lng: p[1], lat: p[0] });
                _x = _x + parseFloat(p[1]);
                _y = _y + parseFloat(p[0]);
              }
              // let polygonLayer =  new BMap.Polygon(posArrary,
              //     {strokeColor:"blue", strokeWeight:.5, strokeOpacity:0.85})
              // map.addOverlay(polygonLayer)
              this.polygonLayerList.push(posArrary);

              _x = _x / vertexArrary.length;
              _y = _y / vertexArrary.length;

              this.labelPos.push({
                lng: _x,
                lat: _y,
                name: this.fenceList[i].fenceName,
              });
            }
          }
        }
      });
    },
    clickTracePointHandler(posinfo) {
      let posInfo = posinfo;
      // this.pointWindowPos = {lat:e.point.lat,lng:e.point.lng}
      // this.eno = equipList[0].carNumber+' | '+equipList[0].entityNumber+' | '+equipList[0].entityFactory
      this.pointDetail.carNo = this.eno.split("|")[0].trim();
      this.pointDetail.equipNo = this.eno.split("|")[1].trim();
      this.pointDetail.equipType = this.formatEquipType(
        this.eno.split("|")[2].trim()
      );
      this.pointDetail.speed = posInfo.speed;
      this.pointDetail.time = this.timeFormate(posInfo.time);
      if (posInfo.locDesc) {
        this.pointDetail.pos = posInfo.locDesc;
        this.pointInfoWindow = true;
      } else {
        traceApi
          .getPosDetail(posinfo.pathPos.lat, posinfo.pathPos.lng)
          .then((res) => {
            if (res.code == 0) {
              this.pointDetail.pos = res.result;
            }
            this.pointInfoWindow = true;
          });
      }
      //this.pointWindowPos
    },
  },
};
</script>
<style scoped>
.map {
  width: 100%;
  height: 100%;
}
.trace_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.map_wrap {
  flex: 1;
}
.infoBoard {
  /*background: #FFF;*/
  margin-bottom: 10px;
  flex-direction: column;
}
.condion {
  flex: 1;
  /*background: #FFF;*/
  transition: all 0.1s ease-in 0s;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bar {
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #3b7bcf;
  cursor: pointer;
}
.cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}
._input {
  width: 200px;
}
.cell_btn {
  margin-right: 10px;
  background: #3b7bcf;
  color: #fff;
}
.imageBtn {
  width: 30px;
  height: 30px;
  background: #3b7bcf;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.searchResult {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.imageBtn img {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.trace_control_panel {
  /*width: 500px;*/
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  padding-right: 10px;
  box-shadow: 4px 4px 4px #8c939d;
}
.title1 {
  background: #3b7bcf;
  color: #fff;
  height: 50px;
  width: 100px;
  text-align: center;
  line-height: 50px;
  margin-right: 20px;
}
.btn1 {
  font-size: 18px;
  text-align: center;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
}
.btn2 {
  font-size: 18px;
  text-align: center;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.playSpeed {
  background: #3b7bcf;
  color: #fff;
  border: none;
}
.w_desc {
  margin-top: 10px;
  color: #2c2c2c;
  font-weight: bold;
}
.w_item span:nth-child(1) {
  color: #2c2c2c;
  font-weight: bold;
  margin-top: 4px;
}
.search_result {
  line-height: normal;
  padding: 7px;
}
.search_result .name {
  font-size: 14px;
}
.search_result .addr {
  font-size: 12px;
  color: #b4b4b4;
}
</style>
