<template>
  <div class="user_info">
    <div class="user_name">
      <el-image :src="userInfo.userImage" fit="fix" style="width: 200px;height: 200px"/>
      <span>{{userInfo.userName}}</span>
    </div>
    <div class="user_detail">
      <div class="item">
        <div>真实姓名</div>
        <div>{{userInfo.realName}}</div>
      </div>
      <el-divider/>
      <div class="item">
        <div>联系电话</div>
        <div>{{userInfo.contactPhone}}</div>
        <div>联系地址</div>
        <div>{{userInfo.contactPlace}}</div>
      </div>
      <el-divider/>
      <div class="item">
        <div>注册时间</div>
        <div>{{userInfo.joinTime}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userInfo:{
        userImage: require('../assets/images/carIcon.png'),
        userName: 'TestUser',
        realName: '张三',
        contactPlace:'沈阳市皇姑区XX街XX路',
        contactPhone: '13900000000',
        joinTime:'2021-04-21 00:00:00',
      }
    }
  }
}
</script>
<style scoped>
.user_info{
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  align-items: flex-start;
}
.user_name{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #2c2c2c;
  font-weight: bold;
}
.user_name span{
  margin-top: 10px;
}
.user_detail{
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  flex: 1;
}
.item{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.item div:nth-child(2n-1){
  color: #8c939d;
  width: 60px;
  text-align: right;
}
.item div:nth-child(2n){
  color: #2c2c2c;
  font-size: 18px;
  margin-left: 20px;
  margin-right: 20px;
  min-width: 200px;
}
</style>