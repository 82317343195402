<template>
  <div ref="page" class="data_wrap">
    <div class="search_cond">
      <el-select v-model="searchOption.cityName" class="handle-select mr10 mt10" placeholder="城市" clearable>
        <el-option v-for="item in cities" :key="item.id" :label="item.name" :value="item.name">
          <span style="float: left">{{ item.name }}</span>
          <span style="float: right; color: #8492a6; font-size: 13px">{{
            item.name
          }}</span>
        </el-option>
      </el-select>
      <el-select v-model="searchOption.carStatus" class="handle-select mr10 mt10" placeholder="车辆状态" clearable>
        <el-option key="1" label="运营" value="1"></el-option>
        <el-option key="2" label="下线" value="2"></el-option>
        <el-option key="3" label="采购" value="3"></el-option>
      </el-select>
      <el-input v-model="searchOption.carNum" placeholder="车牌号" class="handle-input mr10 mt10" clearable></el-input>
      <el-input v-model="searchOption.carName" placeholder="车辆名称" class="handle-input mr10 mt10" clearable></el-input>
      未行驶时长：
      <el-input-number style="width: 60px" v-model="searchOption.stayStart" :controls="false"></el-input-number>
      <span style="margin: 0 5px">-</span>
      <el-input-number style="width: 60px" v-model="searchOption.stayEnd" :controls="false"></el-input-number>
      <span class="mr10">小时</span>
      <!-- <el-select v-model="searchOption.carType" class="handle-select mr10 mt10" placeholder="车辆类型" @change="carTypeChange"
        clearable>
        <el-option key="1" label="运营订单" value="1"></el-option>
        <el-option key="2" label="非运营订单" value="2"></el-option>
        <el-option key="3" label="在库" value="3"></el-option>
      </el-select> -->
      <el-select v-model="searchOption.orderSource" class="handle-select mr10 mt10" placeholder="运营订单来源"
        v-if="searchOption.carType == 1" clearable>
        <el-option key="1" label="自营" value="1"></el-option>
        <el-option key="2" label="携程" value="2"></el-option>
        <el-option key="3" label="悟空" value="3"></el-option>
        <el-option key="4" label="凹凸" value="4"></el-option>
        <el-option key="5" label="租租" value="5"></el-option>
        <el-option key="8" label="飞猪" value="8"></el-option>
        <el-option key="9" label="南航" value="9"></el-option>
        <el-option key="11" label="哈啰" value="11"></el-option>
        <el-option key="13" label="车生活" value="13"></el-option>
        <el-option key="15" label="滴滴租车" value="15"></el-option>
        <el-option key="99" label="其它" value="99"></el-option>
      </el-select>

      <el-select v-model="searchOption.orderSource" class="handle-select mr10 mt10 mb10" placeholder="非运营订单来源"
        v-if="searchOption.carType == 2" clearable>
        <el-option key="1" label="整备" value="1"></el-option>
        <el-option key="2" label="维保" value="2"></el-option>
        <el-option key="3" label="调车" value="3"></el-option>
        <el-option key="4" label="摆渡车" value="4"></el-option>
        <el-option key="99" label="其它" value="99"></el-option>
      </el-select>
      <el-select v-model="searchOption.entityFactory" class="handle-select mr10 mt10 mb10" placeholder="设备厂商" clearable>
        <el-option key="1" label="四海行" value="1"></el-option>
        <el-option key="2" label="艾能" value="2"></el-option>
        <el-option key="3" label="自有硬件" value="3"></el-option>
        <el-option key="4" label="中瑞" value="4"></el-option>
        <el-option key="5" label="赛格" value="5"></el-option>
      </el-select>
      <el-select v-model="searchOption.entityType" class="handle-select mr10 mt10 mb10" placeholder="设备类型" clearable>
        <el-option key="1" label="OBD" value="1"></el-option>
        <el-option key="2" label="无线" value="2"></el-option>
        <el-option key="3" label="有线" value="3"></el-option>
        <el-option key="4" label="断油电" value="4"></el-option>
        <el-option key="5" label="静默" value="5"></el-option>
      </el-select>
      <el-select v-model="searchOption.troubleStatus" class="handle-select mr10 mt10 mb10" placeholder="设备故障状态" clearable>
        <el-option key="0" label="正常" value="0"></el-option>
        <el-option key="1" label="故障" value="1"></el-option>
      </el-select>
      <el-select v-model="searchOption.sort" class="handle-select mr10 mt10 mb10" placeholder="未行驶时长" clearable>
        <el-option key="0" label="升序" value="0"></el-option>
        <el-option key="1" label="降序" value="1"></el-option>
      </el-select>

      <el-button type="primary" icon="el-icon-search" @click="handleSearch()" class="ml20 mt10 searchBtn">搜索</el-button>
      <el-button type="success" @click="ExcelEntity()" class="cell_btn iconfont icon-daochu">导出</el-button>
    </div>
    <div class="data_list" style="margin-top: 10px">
      <el-table v-loading="isLoading" ref="table" :data="tableData" highlight-current-row :height="contentHeight - 120"
        :header-cell-style="{ background: '#FFF', color: '#212121' }">
        <el-table-column type="index" label="序号" :index="indexMethod" align="left" width="50" />
        <el-table-column label="订单来源" align="left" prop="orderSourceStr" />
        <el-table-column label="订单号" align="left" prop="orderNumber">
          <template slot-scope="scope">
            <div class="label_link" type="text" @click="orderEdit(scope.row.orderNumber)" style="user-select: unset">
              {{ scope.row.orderNumber }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="取车时间" align="left" prop="realPickTime" />
        <el-table-column label="计划还车时间" align="left" prop="planGiveTime" />

        <!-- <el-table-column label="起止日期" align="left" prop="startEndTime" /> -->
        <el-table-column label="城市" align="left" prop="carCity" />
        <el-table-column label="车牌号" align="left" width="160">
          <template slot-scope="scope">
            <el-button type="text" @click="showCarInfo(scope.row)">{{
              scope.row.carNumber
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="车辆状态" align="left">
          <template slot-scope="scoped">
            <div>
              <el-tag effect="dark" type="success" v-if="scoped.row.carStatus == 1">
                运营
              </el-tag>
              <el-tag effect="dark" type="info" v-if="scoped.row.carStatus == 2">
                下线
              </el-tag>
              <el-tag effect="dark" type="danger" v-if="scoped.row.carStatus == 3">
                采购
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="车辆名称" align="left" prop="carName" />

        <el-table-column label="设备号" align="left" prop="entityNumberStr" />
        <el-table-column label="故障状态" align="left" prop="troubleStatus">
          <template #default="scoped">
            <div>
              <el-tag effect="dark" type="success" v-if="scoped.row.troubleStatus == 0"
                @click="changeTroubleStatus(scoped.row, 1)">
                正常
              </el-tag>
              <el-tag effect="dark" type="info" v-if="scoped.row.troubleStatus == 1"
                @click="changeTroubleStatus(scoped.row, 0)">
                故障
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="未行驶时长" align="left" prop="stayDis" />
        <el-table-column label="最后时间" align="left" prop="stayTimeStr" />
        <el-table-column label="最后位置" align="left" prop="locDesc" />

        <el-table-column label="操作" align="left" fixed="right" width="160">
          <template slot-scope="scope">
            <el-row>
              <el-col :span="12">
                <el-button type="text" icon="el-icon-location-information" @click="
                  locationEquipment(
                    scope.row.entityNumber,
                    scope.row.entityFactory
                  )
                ">位置</el-button>
              </el-col>
              <el-col :span="12">
                <el-button type="text" icon="el-icon-aim" @click="toTrace(scope.row)">轨迹</el-button>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next,total"
          :total="searchOption.totalCount" :page-size="searchOption.pageSize">
        </el-pagination>
      </div>
    </div>

    <!--      车辆信息-->
    <el-dialog :visible.sync="carInfoVisible" v-if="carInfoVisible" :show-close="false" width="60%">
      <div slot="title" class="dialog_header">
        <span>车辆信息</span>
        <i class="el-icon-close" style="font-size: 26px" @click="carInfoVisible = false" />
      </div>
      <CarInfo :carInfoObj="carInfo" />
    </el-dialog>

    <el-dialog :visible.sync="realTimePosVisible" v-if="realTimePosVisible" :show-close="false" :fullscreen="fullscreen"
      :class="fullscreen ? 'nopadding dialogFullSc' : 'nopadding dialogLessSc'">
      <div slot="title" class="dialog_header">
        <span>车辆实时位置</span>
        <div>
          <i class="iconfont icon-zuixiaohua_huaban1" style="font-size: 26px" @click="fullscreen = false" />
          <i class="iconfont icon-zuidahua" style="font-size: 26px" @click="fullscreen = true" />
          <i class="el-icon-close" style="font-size: 26px" @click="realTimePosVisible = false" />
        </div>
      </div>
      <VehiclePosition :equipNumber="selectEquipNumber" :equipFactory="selectEquipFactory" page="equipList" />
    </el-dialog>

    <el-dialog :visible.sync="orderTraceVisible" v-if="orderTraceVisible" :show-close="false" class="nopadding"
      :style="'height:' + pageHeight * 0.9 + 'px'" width="60%">
      <div slot="title" class="dialog_header">
        <span>车辆轨迹</span>
        <i class="el-icon-close" style="font-size: 26px" @click="orderTraceVisible = false" />
      </div>
      <VehicleTrace :equipNumber="selectEquipNumber" :equipFactory="selectEquipFactory" page="equipList" />
    </el-dialog>

    <!--      订单查看-->
    <el-dialog :visible.sync="orderEditVisible" v-if="orderEditVisible" :show-close="false" class="nopadding" width="60%">
      <div slot="title" class="dialog_header">
        <span>订单查看</span>
        <i class="el-icon-close" style="font-size: 26px" @click="orderEditVisible = false" />
      </div>
      <OrderEdit :isCheck="true" :orderNumber="selectOrderNumber" @closeWindow="orderEditVisible = false" />
    </el-dialog>
  </div>
</template>
<script>
import { stayTimeOut } from "@/api/statistics/timeOutNotRun";

// import equipmentApi from "@/api/equipment";
import traceApi from "@/api/trace";
import CarInfo from "@/components/CarInfo";
import VehiclePosition from "@/components/VehiclePosition";
import VehicleTrace from "@/components/VehicleTrace";
import fenceApi from "@/api/fence";
import monitor from "@/api/monitor";
import { Message } from "element-ui";
import { export2File } from "@/api/exp2file";
import OrderEdit from "@/components/OrderEdit";
export default {
  data() {
    return {
      contentHeight: 0,
      searchOption: {
        equipSn: "",
        equipName: "",
        status: "",
        pageSize: 10,
        cur_page: 1,
        totalCount: 0,
        offline: "",
        carNumber: "",
        isInstock: "",

        entityFactory: "",
        cityName: "",
        stayStart: 24,
        stayEnd: undefined,
        carType: "1",
        carStatus: '',
        carNum: '',
        carName: '',
        entityType: "",
        troubleStatus: '',
        sort: ''
      },
      tableData: [],
      realTimePosVisible: false,
      orderTraceVisible: false,
      carInfoVisible: false,
      selectOrderNumber: "",
      selectOrderCity: "",
      selectEquipNumber: "",
      selectEquipFactory: "",
      carInfo: {},
      isLoading: false,
      cities: [],
      orderEditVisible: false,
    };
  },
  components: { CarInfo, VehiclePosition, VehicleTrace, OrderEdit },
  updated() { },
  mounted() {
    this.$nextTick(() => {
      this.contentHeight = this.$refs.page.offsetHeight;
    });
    this.loadData();
    this.getCity();
  },
  methods: {
    changeTroubleStatus(e, status) {

      this.$confirm("是否切换故障状态?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          macid: e.entityNumber,
          entity_factory: e.entityFactory,
          troubleStatus: status,
        };
        monitor.editTrouble(param).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.loadData();
          } else {
            Message.error({
              message: res.msg,
            });
          }
        });
      });
    },
    orderEdit(v) {
      //订单修改
      this.selectOrderNumber = v;
      this.orderEditVisible = true;
    },
    getCity() {
      fenceApi.findCitys().then((res) => {
        if (res.code == 0) {
          this.cities = res.result;
        }
      });
    },
    handleSearch() {
      if (this.searchOption.stayStart > this.searchOption.stayEnd) {
        this.$message.warning("未行驶时长搜索条件前边不能大于后边");
        return false;
      }
      if (!this.searchOption.stayStart && !this.searchOption.stayEnd) {
        this.$message.warning("未行驶时长搜索条件不能全为空");
        return false;
      }
      this.loadData();
    },
    handleCurrentChange(val) {
      this.searchOption.cur_page = val;
      this.loadData();
    },
    loadData() {
      this.isLoading = true;
      let param = {
        pageNum: this.searchOption.cur_page,
        pageSize: this.searchOption.pageSize,
        entityFactory: this.searchOption.entityFactory,
        cityName: this.searchOption.cityName,
        stayStart: this.searchOption.stayStart,
        stayEnd: this.searchOption.stayEnd,
        carType: this.searchOption.carType,
        orderSource:
          this.searchOption.carType == 1 || this.searchOption.carType == 2
            ? this.searchOption.orderSource
            : "",
        carStatus: this.searchOption.carStatus,
        carNum: this.searchOption.carNum,
        carName: this.searchOption.carName,
        entityType: this.searchOption.entityType,
        troubleStatus: this.searchOption.troubleStatus,
        sort: this.searchOption.sort

      };
      stayTimeOut(param)
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.result.list;
            this.searchOption.totalCount = res.result.total;
          }
          this.$nextTick(() => {
            this.$refs.table.doLayout();
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    /**
     *
     */
    ExcelEntity() {
      let param = {
        entityFactory: this.searchOption.entityFactory,
        cityName: this.searchOption.cityName,
        stayStart: this.searchOption.stayStart,
        stayEnd: this.searchOption.stayEnd,
        carType: this.searchOption.carType,
        orderSource:
          this.searchOption.carType == 1 || this.searchOption.carType == 2
            ? this.searchOption.orderSource
            : "",
        carStatus: this.searchOption.carStatus,
        carNum: this.searchOption.carNum,
        carName: this.searchOption.carName,
        entityType: this.searchOption.entityType,
        troubleStatus: this.searchOption.troubleStatus,
        sort: this.searchOption.sort
      };

      let myObj = {
        url: process.env.VUE_APP_BASE_API + "/statistics/stayTimeOutExport",
        fileName: "超时未行驶统计表",
        data: param,
      };
      export2File(myObj);
    },
    /**
     * 定位监控的设备
     */
    locationEquipment(v, c) {
      // this.$router.push({path:'/trace',params:{eno:'1111111'}})
      // this.selectEquipNumber = v
      // this.selectEquipFactory = c
      // this.realTimePosVisible = true
      // this.fullscreen = true
      let params = { equipNumber: v, equipFactory: c, page: "equipList" };

      const { href } = this.$router.resolve({
        path: "/position",
        query: params,
      });
      // console.log(href)
      window.open(href, "_blank", "toolbar=yes, width=900, height=700");
    },
    /**
     * 订单轨迹
     */
    toTrace(obj) {
      let params = {
        carNumber: obj.carNumber,
        pickTime: obj.editTime,
        factory: obj.entityFactory,
        entityNumber: obj.entityNumber
      };
      const { href } = this.$router.resolve({
        path: "/trace",
        query: params,
      });
      // console.log(href)
      window.open(href, "_blank", "toolbar=yes, width=900, height=700");

      // this.$router.push({
      //   path: "/property/trace",
      //   query: {
      //     equipId: obj.entityNumber,
      //     pickTime: obj.editTime,
      //     factory: obj.entityFactory,
      //   },
      // });
    },
    getEntitylocation(efactory, enumber) {
      traceApi.getCurrentPos(enumber, efactory).then((res) => {
        //console.log(res);
        if (res.code == 0) {
          console.log(res.result);
          //console.log(res.result.locDesc+"/"+res.result.locTimeStr);
          return res.result.locDesc + "/" + res.result.locTimeStr;
          // this.center = {lng:res.result.longitude, lat:res.result.latitude}
          // this.showInfoWindow = true
          // this.posInfo = res.result
        } else {
          return "实时位置获取失败";
          // Message.error({
          //   message: '实时位置获取失败！实时位置获取失败',
          // })
        }
      });
    },

    indexMethod(index) {
      let curPage = this.searchOption.cur_page;
      let pageSize = this.searchOption.pageSize;
      return index + 1 + (curPage - 1) * pageSize;
    },
    showCarInfo(obj) {
      this.carInfoVisible = true;
      this.carInfo = obj;
      this.carInfo.status = obj.carStatus;
    },
  },
};
</script>
<style scoped>
.data_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.handle-input {
  width: 150px;
  display: inline-block;
}

.handle-select {
  width: 120px;
}

.searchBtn {
  height: 30px;
  background: #3b7bcf;
}

.ExcelBtn {
  height: 30px;
  background: #3dd123;
}

.etype {
  font-weight: bold;
  color: #212121;
}

.verticalCol {
  display: flex;
  flex-direction: column;
}

.myDrawer {
  width: 60%;
}

.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #fff;
}

.btnOnline {
  color: #3b7bcf;
}

.btnOutLine {
  color: #8c939d;
}

.label_link {
  color: #3b7bcf;
  text-decoration: underline;
  cursor: pointer;
}

.label_link：hover {
  cursor: pointer;
}
</style>
<style less>
.el-dialog__header {
  background: rgb(26, 36, 62);
  display: flex;
}
</style>
