<template>
  <div ref="page" class="data_wrap">
    <div class="search_cond" ref="search_cond">
      <el-input v-model="searchOption.orderSn" placeholder="订单编号" class="handle-input mr10 mt10"></el-input>

      <el-input v-model="searchOption.vehicleSn" placeholder="车牌号" class="handle-input mr10 mt10"></el-input>

      <el-input v-model="searchOption.carName" placeholder="车辆名称" class="handle-input mr10 mt10"></el-input>

      <el-input v-model="searchOption.carVin" placeholder="车架号" class="handle-input mr10 mt10"></el-input>

      <el-input v-model="searchOption.userName" placeholder="用户姓名" class="handle-input mr10 mt10"></el-input>

      <el-select v-model="searchOption.warnType" class="handle-select mr10 mt10" placeholder="报警类型"
        @click="warnTypeVisible = true" clearable>
        <el-option key="1" label="设备报警" value="1"></el-option>
        <!-- <el-option key="2" label="入围栏报警" value="2"></el-option>
        <el-option key="3" label="出围栏报警" value="3"></el-option> -->
        <el-option key="2" label="围栏报警" value="2"></el-option>
        <el-option key="4" label="超时未还车" value="4"></el-option>
        <el-option key="5" label="超时未行驶" value="5"></el-option>
        <el-option key="7" label="出封闭区域报警" value="7"></el-option>
        <el-option key="8" label="超时离线" value="8"></el-option>
      </el-select>
      <el-select v-model="searchOption.orderType" class="handle-select mr10 mt10" placeholder="订单类型"
        @click="warnTypeVisible = true" clearable>
        <el-option key="1" label="自营" value="1"></el-option>
        <el-option key="2" label="携程" value="2"></el-option>
        <el-option key="3" label="悟空" value="3"></el-option>
        <el-option key="4" label="凹凸" value="4"></el-option>
        <el-option key="5" label="租租" value="5"></el-option>
        <el-option key="8" label="飞猪" value="8"></el-option>
        <el-option key="9" label="南航" value="9"></el-option>
        <el-option key="11" label="哈啰" value="11"></el-option>
        <el-option key="13" label="车生活" value="13"></el-option>
        <el-option key="15" label="滴滴租车" value="15"></el-option>
        <el-option key="99" label="其它" value="99"></el-option>
      </el-select>

      <!-- <el-input v-model="searchOption.city" placeholder="城市"
                class="handle-input mr10 mt10"></el-input> -->
      <el-select v-model="searchOption.city" class="handle-select mr10 mt10" placeholder="城市" clearable>
        <el-option v-for="item in cities" :key="item.id" :label="item.name" :value="item.name">
          <span style="float: left">{{ item.name }}</span>
          <span style="float: right; color: #8492a6; font-size: 13px">{{
            item.name
          }}</span>
        </el-option>
      </el-select>
      <el-select v-model="searchOption.status" class="handle-select mr10 mt10" placeholder="是否消除" clearable>
        <el-option key="0" label="未消除" value="0"></el-option>
        <el-option key="1" label="已消除" value="1"></el-option>
      </el-select>
      <el-select v-model="searchOption.clearStatus" class="handle-select mr10 mt10" placeholder="是否提醒" clearable>
        <el-option key="0" label="未提醒" value="0"></el-option>
        <el-option key="1" label="已提醒" value="1"></el-option>
      </el-select>
      <el-select v-model="searchOption.orderStatus" class="handle-select mr10 mt10" placeholder="订单状态" clearable>
        <el-option key="1" label="确认取车" value="1"></el-option>
        <el-option key="2" label="确认还车" value="2"></el-option>
        <el-option key="3" label="订单取消" value="3"></el-option>
      </el-select>
      <span class="ml10">下单时间：</span>
      <el-date-picker v-model="orderTime" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至"
        start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
      <span class="ml10">取车时间：</span>
      <el-date-picker v-model="realPickTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至"
        start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
      <span class="ml10">预计还车时间：</span>
      <el-date-picker v-model="planGiveTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至"
        start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
      <span class="ml10">报警时间：</span>
      <el-date-picker v-model="alarmDateRanges" type="datetimerange" range-separator="至" start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>

      <el-select v-model="searchOption.isFocus" class="handle-select ml10 mr10 mt10" placeholder="重点关注" clearable>
        <el-option key="0" label="未关注" value="0"></el-option>
        <el-option key="1" label="重点关注" value="1"></el-option>
      </el-select>
      <el-select v-model="searchOption.isMortgage" class="handle-select mr10 mt10" placeholder="抵押车辆" clearable>
        <el-option key="0" label="未抵押" value="0"></el-option>
        <el-option key="1" label="抵押车辆" value="1"></el-option>
      </el-select>
      <el-select v-model="searchOption.isControl" class="handle-select mr10 mt10" placeholder="疫情管控" clearable>
        <el-option key="0" label="未管控" value="0"></el-option>
        <el-option key="1" label="疫情管控" value="1"></el-option>
      </el-select>
      <el-select v-model="searchOption.isPriorityReminder" class="handle-select mr10 mt10" placeholder="优先提醒" clearable>
        <el-option key="0" label="未提醒" value="0"></el-option>
        <el-option key="1" label="优先提醒" value="1"></el-option>
      </el-select>
      <el-select v-model="searchOption.isFirstLease" class="handle-select mr10 mt10" placeholder="首次出租" clearable>
        <el-option key="0" label="非首次" value="0"></el-option>
        <el-option key="1" label="首次出租" value="1"></el-option>
      </el-select>
      <el-select v-model="searchOption.isLockCarnumber" class="handle-select mr10 mt10" placeholder="锁定" clearable>
        <el-option key="0" label="未锁定" value="0"></el-option>
        <el-option key="1" label="锁定" value="1"></el-option>
      </el-select>
      <el-select v-model="searchOption.isFirstApplication" class="handle-select mr10 mt10" placeholder="首次申请" clearable>
        <el-option key="0" label="未申请" value="0"></el-option>
        <el-option key="1" label="首次申请" value="1"></el-option>
      </el-select>


      <el-checkbox :value="timeAuto" @change="timeAutoChange">自动刷新</el-checkbox>
      <el-input-number v-model="searchOption.autoload" controls-position="right" :min="30" :max="300"
        class="handle-input mr10 mt10 ml10" style="width: 100px" @change="autoLoadChange"></el-input-number>秒
      <el-button type="primary" icon="el-icon-search" @click="handleSearch" class="ml20 mt10 searchBtn">搜索</el-button>
      <el-button type="primary" ref="btn_hb" icon="el-icon-document" @click="orderMerge()" class="ml20 mt10 searchBtn">{{
        labelText }}</el-button>
      <el-button type="primary" icon="el-icon-plus" @click="orderAdd()" class="ml20 mt10 searchBtn">新增</el-button>
      <el-button type="success" @click="ExcelEntity()" class="cell_btn iconfont icon-daochu">导出</el-button>
    </div>
    <div class="data_list">
      <el-table ref="table" :data="tableData" v-loading="loading" element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"
        :height="contentHeight - condHeight - 70" :row-class-name="tableRowClassName" @row-click="selectRow"
        :header-cell-style="{ background: '#FFF', color: '#212121' }">
        <el-table-column type="index" label="序号" align="left" :index="indexMethod" width="50" />
        <el-table-column label="订单编号-原订单编号" align="left" width="150">
          <template slot-scope="scope">
            <span>[{{ formatOrderType(scope.row.orderType) }}] </span>
            <span v-if="scope.row.isMortgage == 1"> [抵押车辆] </span>
            <span v-if="scope.row.isControl == 1"> [疫情管控] </span>
            <span v-if="scope.row.isFocus == 1"> [重点关注] </span>
            <div class="label_link" type="text" @click="orderEdit(scope.row.orderNumber)" style="user-select: unset">
              {{ scope.row.orderNumber }}
            </div>
            <div>{{ scope.row.preNumber }}</div>
          </template>
        </el-table-column>
        <el-table-column label="城市-车牌号" align="left" width="100">
          <template slot-scope="scope">
            <div>{{ scope.row.orderCity }}</div>
            <div class="label_link" type="text" @click="selectCar(scope.row.carVin)" style="user-select: unset">
              {{ scope.row.carNumber }}
            </div>
            <div style="display: flex;">
              <div class="cursor" v-if="scope.row.isPriorityReminder == 1"
                @click="updateReminder(scope.row.orderId, '0')">
                [优]
              </div>
              <div class="cursor" v-else @click="updateReminder(scope.row.orderId, '1')">
                [设]
              </div>
              <div class="cursor" v-if="scope.row.isLockCarnumber == 1">
                [锁]
              </div>
            </div>

          </template>
        </el-table-column>
        <el-table-column label="车辆名称" align="left" width="100">
          <template slot-scope="scope">
            <div>
              {{ scope.row.carName }}
              <span v-if="scope.row.isFirstLease == 1"  style="color: red;"> [首] </span>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="车架号" align="left" width="140">
          <template slot-scope="scope">
            <div>{{ scope.row.carVin }}</div>
          </template>
        </el-table-column> -->
        <el-table-column label="客户姓名" align="left" width="100">
          <template slot-scope="scope">
            <div>
              {{ scope.row.orderUser }}
              <span v-if="scope.row.isFirstApplication == 1"  style="color: red;"> [首] </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="身份信息" align="left" width="100">
          <template slot-scope="scope">
            <div>{{ scope.row.identityInformation }}</div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="用户姓名" align="left" width="80">
          <template slot-scope="scope">
            <el-button type="text" @click="selectUser(scope.row.userId)">{{scope.row.orderUser}}</el-button>
          </template>
        </el-table-column> -->
        <!-- <el-table-column
          prop="orderUser"
          label="客户姓名"
          align="left"
          width="80"
        /> -->
        <el-table-column label="订单备注" align="left" width="200">
          <template slot-scope="scope">
            <div class="verticalRow">
              <span v-if="inputOrderFocus != scope.row.orderId">{{
                scope.row.orderRemark
              }}</span>
              <!-- <el-input
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 3 }"
                v-model="scope.row.orderRemark"
                v-if="inputOrderFocus == scope.row.orderId"
                @blur="updateRemark(scope.row)"
              />
              <i
                class="el-icon-edit editIcon label"
                @click="inputOrderFocus = scope.row.orderId"
                >备注</i
              > -->
              <i class="el-icon-search editIcon label" @click="viewOrderList(scope.row.orderNumber)">查看</i>
              <i class="el-icon-search editIcon label" @click="viewOrderLogList(scope.row.orderNumber)">日志</i>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="orderCity"
          label="城市"
          align="left"
          width="80"
        /> -->
        <el-table-column prop="rptime" label="取车时间" align="left" width="150" />
        <el-table-column label="预计还车时间" align="left" width="150" sortable :sort-method="sortByDate">
          <template slot-scope="scope">
            <div class="verticalCol">
              <span>{{ scope.row.pgtime }}</span>
              <!-- <span>{{scope.row.expectReturnCarTime}}</span> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column label="报警类型/时间" align="left" width="280" show-overflow-tooltip>
          <template slot-scope="scope">
            <div>
              {{ isOrderhandleStatuss(scope.row.status) }}
              <span>{{
                formatAlarm(
                  scope.row.typeAlarm,
                  scope.row.warnName,
                  scope.row.fenceName,
                  scope.row.macid,
                  scope.row.entity_factory
                )
              }}</span>
            </div>
            <!-- <span>{{scope.row.warnName}}</span> -->
            <div>{{ scope.row.stime }}</div>
            <div class="cursor" v-if="scope.row.troubleStatus == 1" @click="
              updateTrouble(scope.row.macid, '0', scope.row.entity_factory)
            ">
              [故障]
            </div>
            <div class="cursor" v-else @click="
              updateTrouble(scope.row.macid, '1', scope.row.entity_factory)
            ">
              [设]
            </div>
          </template>
        </el-table-column>
        <el-table-column label="备注" align="left" width="300">
          <template slot-scope="scope">
            <div>

              <div>
                <span v-if="inputFocus != scope.row.alarmId">{{
                  scope.row.remark
                }}</span>
                <el-input type="textarea" :autosize="{ minRows: 1, maxRows: 3 }" v-model="scope.row.remark"
                  v-if="inputFocus == scope.row.alarmId" @blur="updateRemark(scope.row)" />
              </div>
              <div style="display: flex;">
                <div>
                  <i class="el-icon-edit editIcon label" @click="
                    (inputFocus = scope.row.alarmId),
                    (inputContent = scope.row.remart)
                  ">备注</i>
                </div>
                <div>
                  <i class="el-icon-search editIcon label" @click="viewAlarm(scope.row.orderNumber)">查看</i>
                </div>
                <div>
                  <i class="el-icon-delete editIcon label" @click="dealWarn(scope.row.alarmId)">消除</i>
                </div>
                <div>
                  <i class="el-icon-files editIcon label" @click="batchDealWarn(scope.row.alarmId)">批销</i>
                </div>
              </div>

            </div>
          </template>
        </el-table-column>

        <el-table-column label="操作" align="left" width="120" fixed="right">
          <template slot-scope="scope">
            <el-row>
              <el-col :span="12">
                <el-button type="text" icon="el-icon-location-information" class="label" @click="
                  locationEquipment(
                    scope.row.carNumber,
                    scope.row.orderCity,
                    scope.row.macid,
                    scope.row.entity_factory
                  )
                ">位置</el-button>
              </el-col>
              <el-col :span="12">
                <el-button type="text" icon="el-icon-minus" class="label" @click="
                  clearWarn(
                    scope.row.orderNumber,
                    scope.row.typeAlarm,
                    scope.row.typeId,
                    scope.row.entity_factory
                  )
                ">提醒</el-button>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-button class="label" type="text" icon="el-icon-view" @click="pickupWarm(scope.row)">关注</el-button>
              </el-col>
              <el-col :span="12">
                <el-button class="label" type="text" icon="el-icon-aim" @click="toTrace(scope.row)">轨迹</el-button>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
      <!--      订单信息-->
      <el-dialog :visible.sync="orderInfoVisible" v-if="orderInfoVisible" :show-close="false" width="60%">
        <div slot="title" class="dialog_header">
          <span>订单信息</span>
          <i class="el-icon-close" style="font-size: 26px" @click="orderInfoVisible = false" />
        </div>
        <OrderInfo :orderId="selectOrerId" />
      </el-dialog>
      <!--      用户信息-->
      <el-dialog :visible.sync="customerInfoVisible" v-if="customerInfoVisible" :show-close="false" width="60%">
        <div slot="title" class="dialog_header">
          <span>用户信息</span>
          <i class="el-icon-close" style="font-size: 26px" @click="customerInfoVisible = false"></i>
        </div>
        <CustomerInfo />
      </el-dialog>
      <!--      车辆信息-->
      <el-dialog :visible.sync="carInfoVisible" v-if="carInfoVisible" :show-close="false" width="60%">
        <div slot="title" class="dialog_header">
          <span>车辆信息</span>
          <i class="el-icon-close" style="font-size: 26px" @click="carInfoVisible = false" />
        </div>
        <CarInfo :carInfoObj.sync="carInfo" />
      </el-dialog>
      <el-dialog :visible.sync="warnTypeVisible" v-if="warnTypeVisible" :show-close="false" width="60%">
        <div slot="title" class="dialog_header">
          <span>报警类型</span>
          <i class="el-icon-close" style="font-size: 26px" @click="warnTypeVisible = false" />
        </div>
        <WarnType />
      </el-dialog>

      <el-dialog :visible.sync="realTimePosVisible" v-if="realTimePosVisible" :show-close="false" :fullscreen="fullscreen"
        :class="
          fullscreen ? 'nopadding dialogFullSc' : 'nopadding dialogLessSc'
        ">
        <div slot="title" class="dialog_header">
          <span>车辆实时位置</span>
          <div>
            <i class="iconfont icon-zuixiaohua_huaban1" style="font-size: 26px" @click="fullscreen = false" />
            <i class="iconfont icon-zuidahua" style="font-size: 26px" @click="fullscreen = true" />
            <i class="el-icon-close" style="font-size: 26px" @click="realTimePosVisible = false" />
          </div>
        </div>
        <VehiclePosition :orderNumber="selectOrderNumber" :city="selectOrderCity" :equip-number="selectEquipId" />
      </el-dialog>

      <el-dialog :visible.sync="orderTraceVisible" v-if="orderTraceVisible" :show-close="false" :fullscreen="fullscreen"
        :class="
          fullscreen ? 'nopadding dialogFullSc' : 'nopadding dialogLessSc'
        ">
        <div slot="title" class="dialog_header">
          <span>车辆轨迹</span>
          <div>
            <i class="el-icon-close" style="font-size: 26px" @click="orderTraceVisible = false" />
          </div>
        </div>
        <VehicleTrace :equipId="vt_equipId" :pickTime="vt_pickTime" :factory="vt_factory" :carNumber="vt_carNumber" />
      </el-dialog>

      <!--消除告警对话框 -->
      <el-dialog :visible.sync="clearWarnDialogVisible" v-if="clearWarnDialogVisible" :show-close="false"
        class="nopadding" :style="'height:' + pageHeight * 0.9 + 'px'" width="500px">
        <div slot="title" class="dialog_header">
          <span>提醒设置</span>
          <i class="el-icon-close" style="font-size: 26px" @click="clearWarnDialogVisible = false" />
        </div>
        <div class="clear_wrap">
          <div class="clear_setting">
            提醒时间：
            <el-radio-group v-model="checkedClearHours">
              <el-radio label="2">2小时</el-radio>
              <el-radio label="4">4小时</el-radio>
              <el-radio label="6">6小时</el-radio>
            </el-radio-group>
          </div>
          <!--         <div class="clear_setting">-->
          <!--            消除告警类型：-->
          <!--            <el-checkbox-group-->
          <!--                v-model="checkedClearType">-->
          <!--              <el-checkbox label="1" >类型1</el-checkbox>-->
          <!--              <el-checkbox label="2" >类型2</el-checkbox>-->
          <!--              <el-checkbox label="3" >类型3</el-checkbox>-->
          <!--            </el-checkbox-group>-->
          <!--          </div>-->
          <el-button type="primary" class="clearBtn" @click="removeWarn">消除</el-button>
        </div>
      </el-dialog>
      <!--  报警历史列表 -->
      <el-dialog :visible.sync="alarmListVisible" v-if="alarmListVisible" :show-close="false" width="80%">
        <div slot="title" class="dialog_header">
          <span>报警历史列表</span>
          <i class="el-icon-close" style="font-size: 26px" @click="alarmListVisible = false" />
        </div>
        <AlarmListInfo :orderNum.sync="selectOrderNumber" />
      </el-dialog>
      <!--      订单新增-->
      <el-dialog :visible.sync="orderAddVisible" v-if="orderAddVisible" :show-close="false" class="nopadding" width="60%">
        <div slot="title" class="dialog_header">
          <span>订单新增</span>
          <i class="el-icon-close" style="font-size: 26px" @click="orderAddVisible = false" />
        </div>
        <OrderAdd @closeWindow="orderAddVisible = false" />
      </el-dialog>
      <!--      订单修改-->
      <el-dialog :visible.sync="orderEditVisible" v-if="orderEditVisible" :show-close="false" class="nopadding"
        width="60%">
        <div slot="title" class="dialog_header">
          <span>订单修改</span>
          <i class="el-icon-close" style="font-size: 26px" @click="orderEditVisible = false" />
        </div>
        <OrderEdit :orderNumber="selectOrderNumber" @closeWindow="orderEditVisible = false" />
      </el-dialog>
      <!--      订单合并 -->
      <el-dialog :visible.sync="orderMergeVisible" v-if="orderMergeVisible" :show-close="false" class="nopadding"
        :style="'height:' + pageHeight * 0.9 + 'px'" width="60%">
        <div slot="title" class="dialog_header">
          <span>订单合并</span>
          <i class="el-icon-close" style="font-size: 26px" @click="orderMergeVisible = false" />
        </div>
        <OrderMerge />
      </el-dialog>
      <!--  订单备注列表 -->
      <el-dialog :visible.sync="orderListVisible" v-if="orderListVisible" :show-close="false" width="60%">
        <div slot="title" class="dialog_header">
          <span>订单备注列表</span>
          <i class="el-icon-close" style="font-size: 26px" @click="orderListVisible = false" />
        </div>
        <OrderRemarkListInfo :orderNum.sync="selectOrderNumber" />
      </el-dialog>
      <!--  订单日志列表 -->
      <el-dialog :visible.sync="orderLogListVisible" v-if="orderLogListVisible" :show-close="false" width="90%"
        top="50px">
        <div slot="title" class="dialog_header">
          <span>订单日志列表</span>
          <i class="el-icon-close" style="font-size: 26px" @click="orderLogListVisible = false" />
        </div>
        <orderLogList :orderNum.sync="selectOrderNumber" />
      </el-dialog>
    </div>
    <div class="pagination">
      <el-pagination background @size-change="handleSizeChange" :current-page.sync="searchOption.cur_page"
        :page-sizes="[10, 20, 30, 50, 100]" @current-change="handleCurrentChange" layout="prev, pager, next,total,sizes"
        :total="searchOption.totalCount" :page-size="20">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import OrderInfo from "@/components/OrderInfo";
import CustomerInfo from "@/components/CustomerInfo";
import CarInfo from "@/components/CarInfo";
import WarnType from "./components/warnType";
import VehiclePosition from "./components/vehiclePosition";
import VehicleTrace from "./components/vehicleTrace";
import AlarmListInfo from "./components/alarmListInfo";
import OrderMerge from "@/components/OrderMerge";
import OrderAdd from "@/components/OrderAdd";
import OrderEdit from "@/components/OrderEdit";
import orderLogList from "@/components/OrderLog";
import OrderRemarkListInfo from "@/components/OrderRemarkList";
import monitor from "@/api/monitor";
import { Message } from "element-ui";
import fenceApi from "@/api/fence";
import {
  formatOrderType,
  formatAlarmType,
  formatAlarm,
  isOrderhandleStatuss,
} from "@/api/orderview";
import { export2File } from "@/api/exp2file";
//import {formatFactory} from '../api/carview'//equipTypeFormat

export default {
  data() {
    return {
      contentHeight: 0,
      condHeight: 0,
      alarmDateRanges: [], //报警时间
      searchOption: {
        orderSn: "",
        vehicleSn: "",
        userName: "",
        warnType: "",
        city: "",
        status: "0",
        clearStatus: "0",
        orderStatus: "1",
        pageSize: 20,
        cur_page: 1,
        totalCount: 0,
        autoload: "300",
        orderType: "",
        isFocus: "",
        carName: "",
        carVin: "",
        isMortgage: '',
        isControl: '',
        isPriorityReminder: '',
        isFirstLease: '',
        isLockCarnumber: '',
        isFirstApplication: '',
      },
      orderTime: [],
      realPickTime: [],
      planGiveTime: [],
      tableData: [],
      orderInfoVisible: false,
      customerInfoVisible: false,
      carInfoVisible: false,
      warnTypeVisible: false,
      realTimePosVisible: false,
      orderTraceVisible: false,
      clearWarnDialogVisible: false,
      orderAddVisible: false,
      orderEditVisible: false,
      alarmListVisible: false,
      orderListVisible: false,
      orderLogListVisible: false,
      orderMergeVisible: false,
      fullscreen: true,
      pageHeight: 0,
      inputFocus: "",
      inputOrderFocus: "",
      inputContent: "",
      selectId: "",
      checkedClearHours: 0,
      checkedClearType: 0,
      selectOrerId: "",
      cities: [],
      timeAuto: true,
      selectOrderNumber: "",
      selectOrderCity: "",
      carInfo: null,
      selectOrderStartTime: null,
      selectEquipId: "",
      timer: null,
      mergetimer: null,
      pageSize: {},
      autoOrderMergeTime: 60, //订单合并检测时间秒
      stypeid: "",
      sfactoryid: "",
      labelText: "",
      selectRowId: "",
      fullscreenLoading: false,
      vt_equipId: "",
      vt_pickTime: "",
      vt_factory: "",
      vt_carNumber: "",
      loading: true,
      remarkstr: "",
      mp3Value: null
    };
  },
  components: {
    VehicleTrace,
    OrderInfo,
    CustomerInfo,
    CarInfo,
    WarnType,
    VehiclePosition,
    AlarmListInfo,
    OrderMerge,
    OrderEdit,
    OrderAdd,
    OrderRemarkListInfo,
    orderLogList,
  },
  // watch: {
  //   // eslint-disable-next-line no-unused-vars
  //   timeAuto(nv, ov) {
  //     if (nv) {
  //       this.initGetData();
  //     } else {
  //       clearInterval(this.timer)
  //       this.timer = null
  //     }
  //   },
  // },
  updated() {
    // this.$nextTick(() => {
    //   console.log(123)
    //   this.$refs.table.doLayout();
    // });
  },
  mounted() {
    this.mp3Value = new Audio('/rent/music.mp3')
    this.alarmDateRanges = [
      new Date(new Date().getTime() - 48 * 60 * 60 * 1000),
      new Date(),
    ];
    this.$nextTick(() => {
      this.contentHeight = this.$refs.page.offsetHeight;
      this.condHeight = this.$refs.search_cond.offsetHeight;
      this.pageHeight = document.documentElement.clientHeight;
      fenceApi.findCitys().then((res) => {
        if (res.code == 0) {
          this.cities = res.result;
        }
      });
      this.loadData();
      this.initGetData()
      this.loadOrderMergeData();
      this.autoLoadOrderMerge(); //订单合并
    });
  },
  methods: {
    timeAutoChange(e) {
      this.timeAuto = e

      if (e) {
        this.initGetData()

      } else {

        clearInterval(this.timer)
        this.timer = null
      }
    },
    autoLoadChange() {

      clearInterval(this.timer)
      this.timer = null
      this.$nextTick(() => {
        this.initGetData()
      })

    },
    ExcelEntity() {
      let startTime = "";
      let endTime = "";
      if (this.alarmDateRanges != null && this.alarmDateRanges.length > 0) {
        let s1 = new Date(this.alarmDateRanges[0]).getTime();
        let s2 = new Date(this.alarmDateRanges[1]).getTime();
        startTime = s1;
        endTime = s2;
      }
      let param = {
        orderNumber: this.searchOption.orderSn,
        carNumber: this.searchOption.vehicleSn,
        orderUser: this.searchOption.userName,
        typeId: this.searchOption.warnType,
        orderCity: this.searchOption.city,
        status: this.searchOption.status,
        clearStatus: this.searchOption.clearStatus,
        orderStatus: this.searchOption.orderStatus,
        orderType: this.searchOption.orderType,
        isFocus: this.searchOption.isFocus,
        startTime: Number(startTime),
        endTime: Number(endTime),
        orderTimeStart:
          this.orderTime && this.orderTime.length > 1 ? this.orderTime[0] : "", // 下单时间开始
        orderTimeEnd:
          this.orderTime && this.orderTime.length > 1 ? this.orderTime[1] : "", // 下单时间结束
        realPickTimeStart:
          this.realPickTime && this.realPickTime.length > 1
            ? this.realPickTime[0]
            : "", // 取车时间开始
        realPickTimeEnd:
          this.realPickTime && this.realPickTime.length > 1
            ? this.realPickTime[1]
            : "", // 取车时间结束
        planGiveTimeStart:
          this.planGiveTime && this.planGiveTime.length > 1
            ? this.planGiveTime[0]
            : "", // 计划还车时间开始
        planGiveTimeEnd:
          this.planGiveTime && this.planGiveTime.length > 1
            ? this.planGiveTime[1]
            : "", // 计划还车时间结束
        isMortgage: this.searchOption.isMortgage,
        isControl: this.searchOption.isControl,
        isPriorityReminder: this.searchOption.isPriorityReminder,
        isFirstLease: this.searchOption.isFirstLease,
        isLockCarnumber: this.searchOption.isLockCarnumber,
        isFirstApplication: this.searchOption.isFirstApplication,
      };

      let myObj = {
        url: process.env.VUE_APP_BASE_API + "/alarm/exportAlarmList",
        fileName: "运营报警列表",
        data: param,
      };
      export2File(myObj);
    },
    //优先提醒
    updateReminder(id, status) {
      this.$confirm("是否切换优先提醒状态?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          orderId: id,
          isPriorityReminder: status,
        };
        monitor.editReminder(param).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.loadData();
          } else {
            Message.error({
              message: res.msg,
            });
          }
        });
      });
    },
    //设为故障
    updateTrouble(id, status, entity_factory) {
      this.$confirm("是否切换故障状态?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          macid: id,
          entity_factory,
          troubleStatus: status,
        };
        monitor.editTrouble(param).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.loadData();
          } else {
            Message.error({
              message: res.msg,
            });
          }
        });
      });
    },
    //
    batchDealWarn(id) {
      this.$confirm("是否批量消除? 操作者为对应登录用户", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          id: id,
        };
        monitor.batchEliminate(param).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.loadData();
          } else {
            Message.error({
              message: res.msg,
            });
          }
        });
      });
    },

    loadData() {
      this.loading = true;
      let startTime = "";
      let endTime = "";
      if (this.alarmDateRanges != null && this.alarmDateRanges.length > 0) {
        let s1 = new Date(this.alarmDateRanges[0]).getTime();
        let s2 = new Date(this.alarmDateRanges[1]).getTime();
        startTime = s1;
        endTime = s2;
      }
      let param = {
        pageNum: this.searchOption.cur_page,
        pageSize: this.searchOption.pageSize,
        orderNumber: this.searchOption.orderSn,
        carNumber: this.searchOption.vehicleSn,
        orderUser: this.searchOption.userName,
        typeId: this.searchOption.warnType,
        orderCity: this.searchOption.city,
        status: this.searchOption.status,
        clearStatus: this.searchOption.clearStatus,
        orderStatus: this.searchOption.orderStatus,
        orderType: this.searchOption.orderType,
        isFocus: this.searchOption.isFocus,
        startTime: Number(startTime),
        endTime: Number(endTime),
        orderTimeStart:
          this.orderTime && this.orderTime.length > 1 ? this.orderTime[0] : "", // 下单时间开始
        orderTimeEnd:
          this.orderTime && this.orderTime.length > 1 ? this.orderTime[1] : "", // 下单时间结束
        realPickTimeStart:
          this.realPickTime && this.realPickTime.length > 1
            ? this.realPickTime[0]
            : "", // 取车时间开始
        realPickTimeEnd:
          this.realPickTime && this.realPickTime.length > 1
            ? this.realPickTime[1]
            : "", // 取车时间结束
        planGiveTimeStart:
          this.planGiveTime && this.planGiveTime.length > 1
            ? this.planGiveTime[0]
            : "", // 计划还车时间开始
        planGiveTimeEnd:
          this.planGiveTime && this.planGiveTime.length > 1
            ? this.planGiveTime[1]
            : "", // 计划还车时间结束
        isMortgage: this.searchOption.isMortgage,
        isControl: this.searchOption.isControl,
        isPriorityReminder: this.searchOption.isPriorityReminder,
        isFirstLease: this.searchOption.isFirstLease,
        isLockCarnumber: this.searchOption.isLockCarnumber,
        isFirstApplication: this.searchOption.isFirstApplication,
      };
      this.tableData = []
      monitor.alarmList(param).then((res) => {

        if (res.code == 0) {
          this.tableData = res.result.list;

          if (res.result.list.filter(i => i.isPriorityReminder == 1).length > 0) {
            this.mp3Value.play()
          }

          // this.$refs.table.bodyWrapper.scrollTop = 0;
          this.searchOption.totalCount = res.result.total;
          this.loading = false;
        } else {
          Message.error({
            message: res.msg,
          });
        }
        // this.$nextTick(() => {
        //   if (this.$refs.table) {
        //     this.$refs.table.doLayout();
        //   }

        // });
        this.loading = false;
      });
    },
    initGetData() {
      let time = this.searchOption.autoload
      // setTimeout(() => {

      // }, time * 1000)
      this.timer = setInterval(() => {
        this.alarmDateRanges = [
          new Date(new Date().getTime() - 48 * 60 * 60 * 1000),
          new Date(),
        ];
        this.loadData();
      }, time * 1000)
    },

    autoLoadOrderMerge() {
      let self = this;
      self.mergetimer = setInterval(function () {
        self.loadOrderMergeData();
      }, self.autoOrderMergeTime * 1000);
    },
    loadOrderMergeData() {
      monitor.getOrderClashCount().then((res) => {
        if (res.code == 0) {
          this.labelText = "订单合并[" + res.result + "]条";
        }
      });
    },
    orderAdd() {
      //订单新增
      this.orderAddVisible = true;
    },
    orderEdit(v) {
      //订单修改
      this.selectOrderNumber = v;
      this.orderEditVisible = true;
    },
    updateRemark(row) {
      let param = {
        id: row.alarmId,
        remark: row.remark,
      };
      if (row.remark.length > 100) {
        Message.error({
          message: "备注内容最多100个字符！",
        });
      }
      monitor
        .editRemark(param)
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            // this.loadData();
          } else {
            row.remark = this.inputContent;
            Message.error({
              message: res.msg,
            });
          }
        })
        .catch(() => {
          row.remark = this.inputContent;
        });
      this.inputFocus = "";
      this.remarkstr = "";
    },
    dealWarn(alarmId) {
      this.$confirm("是否消除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          id: alarmId,
        };
        monitor.handleWarm(param).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            let itemIndex = this.tableData.findIndex(
              (item) => item.alarmId == alarmId
            );
            this.tableData.splice(itemIndex, 1);
            // this.loadData();
          } else {
            Message.error({
              message: res.msg,
            });
          }
        });
      });
    },
    removeWarn() {
      let param = {
        orderNumber: this.selectId,
        addTime: this.checkedClearHours,
        warnType: this.checkedClearType,
        typeid: this.stypeid,
        typefactory: this.sfactoryid,
      };
      monitor.deleteWarm(param).then((res) => {
        if (res.code == 0) {
          this.clearWarnDialogVisible = false;
          //this.loadData();//关闭后不刷新页面
        } else {
          Message.error({
            message: res.msg,
          });
        }
      });
    },
    handleSearch() {
      this.loadData();
    },
    handleCurrentChange(val) {
      this.searchOption.cur_page = val;
      this.loadData();
    },
    handleSizeChange(val) {
      this.searchOption.pageSize = val;
      this.loadData();
    },
    selectOrder(sn) {
      this.selectOrerId = sn;
      this.orderInfoVisible = true;
    },
    viewAlarm(id) {
      this.selectOrderNumber = id;
      this.alarmListVisible = true;
    },
    viewOrderList(id) {
      this.selectOrderNumber = id;
      this.orderListVisible = true;
    },
    viewOrderLogList(id) {
      this.selectOrderNumber = id;
      this.orderLogListVisible = true;
    },
    // selectUser(id){
    //   this.customerInfoVisible = true
    // },
    selectCar(vin) {
      monitor.getCarContent(vin).then((res) => {
        if (res.code == 0) {
          this.carInfo = res.result;
          this.carInfoVisible = true;
        } else {
          Message.error({
            message: res.msg,
          });
        }
      });
      // console.log(id)
      // this.$router.push('/vehicleInfo')
    },
    /**
     * 定位监控的设备
     */
    locationEquipment(v, c, equipId, f) {
      // this.$router.push({path:'/trace',params:{eno:'1111111'}})
      //:orderNumber="selectOrderNumber" :city="selectOrderCity" :equip-number="selectEquipId"
      let params = {
        carNumber: v,
        city: c,
        equipNumber: equipId,
        equipFactory: f,
      };
      const { href } = this.$router.resolve({
        path: "/position",
        query: params,
      });
      // console.log(href)
      window.open(href, "_blank", "toolbar=yes, width=900, height=700");
      // this.selectEquipId = equipId
      // this.selectOrderNumber = v
      // this.selectOrderCity = c
      // this.realTimePosVisible = true
      // this.fullscreen = true         //自动最大化
      //    const { href } = this.$router.resolve({
      //     path: '../components/VehiclePosition',
      //     query: {
      //       selectOrderNumber:v,
      //       selectOrderCity:c
      //     }
      //   });
      //   window.open(href, '_blank');
      // },
    },
    /**
     * 订单轨迹
     */
    // eslint-disable-next-line no-unused-vars
    toTrace(obj) {
      let type = obj.typeAlarm;
      let params = null;
      if (type != 4) {
        params = {
          equipId: obj.macid,
          pickTime: obj.rptime,
          factory: obj.entity_factory,
        };
      } else {
        params = {
          carNumber: obj.carNumber,
          pickTime: obj.rptime,
          factory: obj.entity_factory,
        };
      }
      const { href } = this.$router.resolve({
        path: "/trace",
        query: params,
      });
      // console.log(href)
      window.open(href, "_blank", "toolbar=yes, width=900, height=700");

      // this.orderTraceVisible = true
      // let type = obj.typeAlarm
      // if(type != 4){
      //   this.vt_carNumber=''
      //  this.vt_equipId = obj.macid
      //  this.vt_pickTime = obj.realPickTime
      //   this.vt_factory = obj.entity_factory
      // }else{
      //   this.vt_equipId=''
      //   this.vt_carNumber = obj.carNumber
      //   this.vt_pickTime = obj.realPickTime
      //   this.vt_factory = obj.entity_factory
      // }
    },
    /*消除告警
     */
    clearWarn(id, type, typeid, factoryid) {
      this.selectId = id;
      this.checkedClearType = type;
      this.stypeid = typeid;
      this.sfactoryid = factoryid;
      this.clearWarnDialogVisible = true;
    },
    pickupWarm(obj) {
      let focus = obj.isFocus == 1 ? 0 : 1;
      let param = {
        orderId: obj.orderId,
        isFocus: focus,
      };
      monitor.noticeWarm(param).then((res) => {
        if (res.code == 0) {
          this.loadData();
        }
      });
    },
    formatAlarmType(v) {
      return formatAlarmType(v);
    },
    formatAlarm(typeAlarm, warnName, fenceName, macid, entity_factory) {
      return formatAlarm(typeAlarm, warnName, fenceName, macid, entity_factory);
    },
    formatOrderType(v) {
      return formatOrderType(v);
    },
    isOrderhandleStatuss(v) {
      return isOrderhandleStatuss(v);
    },
    orderMerge() {
      //订单合并
      this.orderMergeVisible = true;
    },
    /**
     * 更改行背景色
     */
    tableRowClassName({ row }) {

      if (row.alarmId == this.selectRowId) {
        return "light-row";
      } else if (row.isFocus == 1) {
        return "notice-row";
      } else {
        return "";
      }
    },
    indexMethod(index) {
      let curPage = this.searchOption.cur_page;
      let pageSize = this.searchOption.pageSize;
      return index + 1 + (curPage - 1) * pageSize;
    },
    // eslint-disable-next-line no-unused-vars
    selectRow(row, column, event) {
      this.selectRowId = row.alarmId;
    },
    sortByDate(obj1, obj2) {
      let val1 = new Date(obj1.pgtime).getTime();
      let val2 = new Date(obj2.pgtime).getTime();
      return val1 - val2;
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
    clearInterval(this.mergetimer);
    this.timer = null
    this.mergetimer = null
  },
};
</script>
<style lang="scss" scoped>
.data_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.handle-input {
  width: 150px;
  display: inline-block;
}

.handle-select {
  width: 160px;
}

.searchBtn {
  height: 30px;
  background: #3b7bcf;
}

.label_link {
  color: #3b7bcf;
  text-decoration: underline;
  cursor: pointer;
}

.label_link：hover {
  cursor: pointer;
}

.verticalCol {
  display: flex;
  flex-direction: column;
}

.verticalRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.myDrawer {
  width: 60%;
}

.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #fff;
}

.verticalRow span {
  margin-right: 4px;
}

.editIcon {
  color: #3b7bcf;
  cursor: pointer;
}

.clear_wrap {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.clear_setting {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.clearBtn {
  width: 60px;
}

.pagination {
  height: 70px;
}

// .search_cond {
//   /* height: 100px; */
// }
.data_list {
  width: 100%;
  flex: 1;
  margin-top: 10px;
}
</style>
<style less>
.el-dialog__header {
  background: rgb(26, 36, 62);
  display: flex;
}

.nopadding .el-dialog__body {
  padding: 0px;
}

.el-table::before {
  z-index: inherit;
}

.el-table .notice-row {
  background: #ff5151;
}

.el-table .light-row {
  background: #f0f9eb;
}

.dialogFullSc .el-dialog__body {
  height: calc(100% - 60px);
  width: 100%;
}

.dialogLessSc .el-dialog__body {
  height: 500px;
  width: 100%;
}

.cursor {
  cursor: pointer;
}
</style>
