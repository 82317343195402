import { post, get, postByQuery } from '@/api/request'

//添加角色
export const roleAdd = params => {
    return post('/auth/roleAdd', params)
}

//角色列表（分页）
export const roleQueryList = params => {
    return post('/auth/roleQueryList', params)
}

//角色列表（不分页）
export const roleQueryPage = params => {
    return post('/auth/roleQueryPage', params)
}

//通过ID获取单条数据
export const roleSelectOne = params => {
    return get('/auth/roleSelectOne', params)
}

//角色修改
export const roleEdit = params => {
    return post('/auth/roleEdit', params)
}

//角色删除
export const roleDelete = params => {
    return postByQuery('/auth/roleDelete', params)
}