<template>
  <div class="order_info">
    <div class="order_detail">
      <el-form ref="orderForm" label-width="100px">
        <el-form-item label="说明" style="margin-bottom: 0; margin-top: 20px" required>
          <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="siteSurveyExplain">
          </el-input>
        </el-form-item>
        <div class="d-f" style="margin-top: 20px">
          <el-form-item label="时间" style="margin-bottom: 0" required>
            <el-date-picker v-model="siteSurveyTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
              placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
          <el-upload ref="upload" class="d-f" style="margin-left: 20px" :limit="2" :action="uploadUrl" :headers="headers"
            name="file" :data="{
              orderNumber,
              siteSurveyTime,
              siteSurveyExplain,
            }" :file-list="fileList" :before-upload="beforeUpload" :on-change="onChange" :on-success="fileSuccess"
            :on-error="fileError" :auto-upload="false" list-type="picture-card">
            <el-button style="margin-left: 20px" size="small" type="primary"
              v-if="fileList.length == 0">上传现勘图片</el-button>
          </el-upload>

          <el-button type="warning" style="margin-left: 20px; height: 32px" @click="submitFile()"
            class=" searchBtn">新增</el-button>
        </div>
        <div class="data_list" style="margin-top: 10px; margin-left: 20px">
          <div style="border-bottom: 1px solid #eeeeee;padding-bottom: 10px;">现堪列表</div>
          <el-table ref="table" :data="tableData" highlight-current-row
            :header-cell-style="{ background: '#FFF', color: '#212121' }">
            <el-table-column type="index" label="序号" :index="indexMethod" align="left" width="50" />
            <el-table-column label="说明" prop="siteSurveyExplain" />
            <el-table-column label="现勘时间" prop="siteSurveyTime" />
            <el-table-column label="查看">
              <template slot-scope="scope">
                <div>
                  <img style="width: 100px;border: 1px solid #dbdbdb;border-radius: 10px;" :src="scope.row.filePath"
                    alt="" @click="checkImg(scope.row)">
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100" prop="left">
              <template slot-scope="scope">
                <el-button type="text" @click="delData(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="item btns">


          <el-button type="primary" @click="closeDialog()" class="mt10 searchBtn">关闭</el-button>
        </div>
      </el-form>
    </div>
    <el-dialog v-if="dialogVisible" :visible.sync="dialogVisible" :show-close="false" class="nopadding" append-to-body
      width="60%">
      <div slot="title" class="dialog_header">
        <span>查看图片</span>
        <i class="el-icon-close" style="font-size: 26px" @click="dialogVisible = false" />
      </div>
      <img style="width: 100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import orderApi from "@/api/order";
import { getToken } from "@/utils/auth";
// import { Message } from "element-ui";
export default {
  data() {
    return {
      uploadUrl: process.env.VUE_APP_BASE_API + "/orderSurveyFile/add",
      headers: {
        Authorization: "Bearer " + getToken("rvToken"),
      },
      tableData: [],
      siteSurveyExplain: "",
      siteSurveyTime: "",
      fileList: [],
      btnDisable: false,
      dialogVisible: false,
      dialogImageUrl: "",
      configUrl: ''
    };
  },
  props: {
    orderNumber: String,
  },
  mounted() {
    this.getDetail();
    this.configUrl = process.env.VUE_APP_BASE_API.replace(
      "plat/",
      ""
    );
  },

  methods: {
    checkImg(e) {
      this.dialogImageUrl = e.filePath
      this.dialogVisible = true;
    },
    delData(e) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        orderApi
          .delOrderSurveyFile({
            id: e.id,
          })
          .then((res) => {
            if (res.code == "0") {
              this.$message.success("删除成功");
              this.getDetail();
            } else {
              this.$message.error(res.msg || "删除");
            }
          });
      });
    },
    fileSuccess(e) {
      if (e.code == "0") {
        this.$message.success("添加成功");
        this.getDetail();
      } else {
        this.$message.error(e.msg || "上传失败");
      }
    },
    fileError() {
      console.log(2);
    },
    submitFile() {
      if (!this.siteSurveyExplain) {
        this.$message.error("说明不能为空");
        return false;
      }
      if (!this.siteSurveyTime) {
        this.$message.error("时间不能为空");
        return false;
      }
      if (this.$refs.upload.uploadFiles.length == 0) {
        this.$message.error("请选择图片");
        return false;
      }
      this.$refs.upload.submit();
    },
    onChange(e) {
      if (this.$refs.upload.uploadFiles.length > 1) {
        this.$refs.upload.uploadFiles = [e];
      }
    },
    beforeUpload() {
      console.log(123);
    },
    getDetail() {
      orderApi
        .orderSurveyFileList({
          orderNumber: this.orderNumber,
        })
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.result;
            console.log(this.tableData, 234);
          }
        });
    },
    indexMethod(index) {
      // let curPage = 1;
      // let pageSize = 2;
      return index + 1;
    },
    submitForm() { },
    closeDialog() {
      this.$emit("closeWindow");
    },
  },
};
</script>
<style lang="scss" scoped>
.order_info {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  height: 100%;
  align-items: flex-start;
}

.car_name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #2c2c2c;
  font-weight: bold;
  /*width:300px;*/
}

.car_name span {
  margin-top: 10px;
}

.order_detail {
  display: flex;
  flex-direction: column;
  /*margin-left: 20px;*/
  flex: 1;
  padding: 0 40px;
  box-sizing: border-box;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item div:nth-child(2n-1) {
  color: #8c939d;

  text-align: right;
}

.item-f-column {
  flex-direction: column;
}

.item-item {
  display: flex;
  align-items: center;
  width: 100%;
}

.mb24 {
  margin-bottom: 24px;
}

.item div:nth-child(2n) {
  color: #2c2c2c;
  font-size: 18px;
  margin-left: 20px;
  margin-right: 20px;
  min-width: 200px;
}

.btns {
  height: 50px;
  width: 100%;
  justify-content: center;
  margin-bottom: 40px;
}

.handle-input {
  width: 180px;
}

.labelGreen {
  color: #5daf34;
  margin-left: 10px;
}

.labelRed {
  color: #e62e00;
  margin-left: 10px;
}

.d-f {
  display: flex;
  align-items: center;
}

::v-deep .el-upload {
  border: none;
  width: 140px;
  height: 100px;
  display: flex;
  align-items: center;
}

::v-deep .el-upload-list {
  border: none;

  .el-upload-list__item {
    margin-bottom: 0;
    width: 100px;
    height: 100px;
  }
}

.img-box {
  width: 100px;
  height: 80px;
  background-color: red;
  border-radius: 10px;
  margin-left: 20px;
}

.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #fff;
}
</style >
