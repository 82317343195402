import { post, postByForm } from './request'

export default {
    /**
     * 获取报警配置信息
     * @param params {"status":"1","entityFactory":"1"}
     * @returns {Promise | Promise<unknown>}
     */
    getAlarmTypeList(params) {
        return post('/alarmtype/queryAllAlarmType', params)
    },
    /**
     * 报警配置修改
     * @param data:[{"id":1,"status":"1"}]
     * @returns {Promise | Promise<unknown>}
     */
    updateAlarmType(data, type) {
        //return post('/alarmtype/updateAlarmType?data='+data)
        let data1 = new FormData();
        data1.append('data', data);
        data1.append('type', type);
        return postByForm('/alarmtype/updateAlarmType', data1)
    },
}