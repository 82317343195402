import {get,post} from './request'

export default {

    /**
     *监控告警列表
     */
    alarmList(params){
        return get('/inCarAlarm/findLocalAlarmPage', params)
    },
    /**
     *监控历史告警列表
     */
     alarmListHistory(params){
        return get('/inCarAlarm/findLocalAlarmHistoryPage', params)
    },
    /**
     * 告警关注
     * @param params
     * @returns {Promise | Promise<unknown>}
     */
    noticeWarm(params){
        return post('/carorder/editFocus?order_id='+params.orderId+'&is_focus='+params.isFocus)
    },
    /**
     * 修改告警备注
     * @param params
     * @returns {Promise | Promise<unknown>}
     */
    editRemark(params){
        return post('/inCarAlarm/EditRemark', params)
    },
    /**
     * 消除报警
     * @param params
     * @returns {Promise | Promise<unknown>}
     */
    deleteWarm(params){
        return post('/inCarAlarm/saveLocalAlarmEliminate?orderNumber='+params.orderNumber+'&addTime='
            +params.addTime+'&warnType='+params.warnType+'&typeid='+params.typeid+'&typefactory='+params.typefactory)
    },
    /**
     * 报警处理
     * @param params
     * @returns {Promise | Promise<unknown>}
     */
    handleWarm(params){
        return post('/inCarAlarm/handleAlarm?id='+params.id)
    },

    
    /**
     * 获取车辆信息
     * @param param
     * @returns {Promise | Promise<unknown>}
     */
    getCarContent(vin){
        return post('/carinfo/queryCarByCarVin?carVin='+vin )
    },
    /**
         * 获取报警历史列表
         * @param params
         * @returns {Promise | Promise<unknown>}
         */
    getAlarmHistoryList(params){
        return post('/inCarAlarm/getAlarmHistoryList?orderNumber='+params.orderNumber+'&pageNum='
            +params.pageNum+'&pageSize='+params.pageSize)
    },
        /**
         * 获取报警历史信息列表
         * @param params
         * @returns {Promise | Promise<unknown>}
         */
         getHisAlarmHistoryList(params){
            return post('/inCarAlarm/getHisAlarmHistoryList?orderNumber='+params.orderNumber+'&pageNum='
                +params.pageNum+'&pageSize='+params.pageSize)
        },
    /**
     * 获取报警历史列表
     * @param params
     * @returns {Promise | Promise<unknown>}
     */
    getOrderClashCount(){
        return post('/carorderclash/getCount')
    },
}
