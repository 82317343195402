<template>
  <div class="fence_wrap">
    <div class="guide">
      <i class="el-icon-arrow-left" @click="back" style="font-weight: bold" />
      <span>添加电子围栏</span>
    </div>
    <div class="infoBoard">
      <div class="condion" ref="content">
        <el-input v-model="fenceForm.fenceName" placeholder="电子围栏名称" class="handle-input required" />

        <el-radio-group v-model="fenceForm.coordType" size="medium">
          <el-radio-button label="wgs84" disabled>GPS经纬度</el-radio-button>
          <el-radio-button label="gcj02" disabled>国测局经纬度</el-radio-button>
          <el-radio-button label="bd09ll">百度经纬度</el-radio-button>
        </el-radio-group>
        <el-select v-model="fenceForm.city" placeholder="切换城市" class="select_city">
          <el-option v-for="item in cities" :key="item.id" :label="item.name" :value="item.name">
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{
              item.name
            }}</span>
          </el-option>
        </el-select>
        <el-button type="primary" icon="el-icon-delete" v-if="fenceForm.shape == 2" @click="reDrawLine"
          class="ml20 mt10 reDrawBtn">重绘</el-button>
        <el-button type="primary" icon="el-icon-plus" @click="addNew" class="ml20 mt10 searchBtn">添加</el-button>
        <span v-if="fenceForm.shape == 2" style="margin-left: 10px">注：鼠标右键停止绘制</span>
      </div>
    </div>
    <span style="margin-bottom: 20px; font-weight: bold; margin-top: 10px">围栏预览</span>

    <div class="maplayer">
      <baidu-map :center="center" class="map" :zoom="zoom" @ready="handler" @click="clickMapEvent"
        @rightclick="newPolyline" @mousemove="syncPolyline">
        <bm-circle :center="circlePath.center" :radius="circlePath.radius" stroke-color="blue" :stroke-opacity="0.5"
          fillColor="rgb(12,21,46)" :stroke-weight="1" @lineupdate="updateCirclePath" :editing="true"
          v-if="fenceForm.shape == 1" />
        <bm-polygon :path="polygonPath" stroke-color="blue" :stroke-opacity="0.5" fillColor="rgb(12,21,46)"
          :stroke-weight="1" :editing="true" @lineupdate="updatePolygonPath" v-if="fenceForm.shape == 2" />

        <!--        <bm-polyline :path="path" v-for="(path,$index) in polyline.paths" :key="$index"></bm-polyline>-->

        <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" />
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" />

        <bm-control :offset="{ width: 20, height: 20 }">
          <div class="fence_type_list">
            <span style="margin-right: 10px">围栏类型</span>
            <el-radio-group v-model="fenceForm.shape" @change="selectShape">
              <el-radio-button label="1">圆行</el-radio-button>
              <el-radio-button label="2">多边形</el-radio-button>
            </el-radio-group>
          </div>
        </bm-control>
      </baidu-map>
      <div class="searchMap">
        <div class="fence_type_list">
          <!-- <span style="margin-right: 10px">围栏类型</span> -->
          <div>
            <el-input v-model="searchMap.name" placeholder="搜索位置,查看位置周边数据" class="handle-input mr10"
              @change="locationMap"></el-input>
            <el-select v-model="searchMap.city" class="handle-select" placeholder="城市" @change="locationMap" clearable>
              <el-option v-for="item in cities" :key="item.id" :label="item.name" :value="item.name">
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                  item.name
                }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import fenceApi from "@/api/fence";
import { Loading, Message } from "element-ui";
export default {
  data() {
    return {
      fenceForm: {
        id: "",
        fenceName: "",
        shape: "0",
        // fenceDesc:'',
        // validTimes:'',
        // createTime:'',
        // creator:'',
        // validCycle:'',
        // validDate:'',
        // validDays:'',
        coordType: "",
        radius: 500,
        longitude: "",
        latitude: "",
        vertexes: "",
        city: "",
        fenceType:"0",
        // alarmCondition: 3,
        // precision: 0
      },
      selectDays: [],
      center: { lng: 116.404, lat: 39.915 },
      zoom: 3,
      circlePath: {
        center: {
          lng: 116.404,
          lat: 39.915,
        },
        radius: 500,
      },
      polygonPath: [{ lng: 116.404, lat: 39.915 }],
      timeRange: [],
      timeRangeList: [],
      extendBar: false,
      locationLoading: null,
      cities: [],
      inDrawLine: false,
      mapInstance: null,
      controlRight: 0,
      searchMap: {
        city: "",
        name: "",
      },
    };
  },
  comments: { BaiduMap },
  mounted() {
    // this.timeRange = ['00:00','00:00']
    // this.timeRangeList = []

    this.$nextTick(() => {
      fenceApi.findCitys().then((res) => {
        if (res.code == 0) {
          this.cities = res.result;
        }
      });
    });
  },
  methods: {
    locationMap() {
      this.center = this.searchMap.city + this.searchMap.name;
    },
    addNew() {
      if (this.fenceForm.fenceName.length == 0) {
        this.$message.error("围栏名称还未填写!");
        return;
      }

      if (this.fenceForm.coordType.length == 0) {
        this.$message.error("坐标类型还未选择!");
        return;
      }
      // if(this.fenceForm.city.length==0){
      //   this.$message.error('围栏城市还未选择!')
      //   return
      // }

      this.fenceForm.radius = "";
      this.fenceForm.latitude = "";
      this.fenceForm.longitude = "";
      this.fenceForm.vertexes = "";
      if (this.fenceForm.shape == 1) {
        this.fenceForm.radius = this.circlePath.radius;
        this.fenceForm.latitude = this.circlePath.center.lat;
        this.fenceForm.longitude = this.circlePath.center.lng;
      } else if (this.fenceForm.shape == 2) {
        if (this.polygonPath.length > 0) {
          let length = this.polygonPath.length;
          let str = "";
          for (let i = 0; i < length; i++) {
            let lng = this.polygonPath[i].lng;
            let lat = this.polygonPath[i].lat;
            str += lat + "," + lng + ";";
          }
          str = str.substring(0, str.length - 1);
          this.fenceForm.vertexes = str;
        }
      }
      fenceApi.add(this.fenceForm).then((res) => {
        if (res.code == "0") {
          Message.success({
            message: "添加成功",
          });
        } else {
          Message.error({
            message: res.msg,
          });
        }
      });
    },
    handler({ BMap, map }) {
      this.locationLoading = Loading.service({
        text: "加载地理位置",
        background: "rgba(0, 0, 0, 0.8)",
      });
      let self = this;
      // let mapStyle={style:'grayscale'}
      // map.setMapStyle(mapStyle)
      map.enableScrollWheelZoom();
      self.zoom = 15;
      if (BMap) {
        self.mapInstance = { _bmap: BMap, _map: map };
        const geolocation = new BMap.Geolocation();
        geolocation.getCurrentPosition((res) => {
          const { lng, lat } = res.point;
          // const { province, city, district, street, street_number } = res.address
          self.center = { lng, lat };
          self.circlePath.center = { lng, lat };
          self.fenceForm.longitude = lng;
          self.fenceForm.latitude = lat;
          self.polygonPath = [];
          // self.polygonPath.push({ lng, lat })
          // console.log(self.circlePath.center)
          // self.choosedLocation = { province, city, district, addr: street + street_number, lng, lat }
          this.$nextTick(() => {
            this.locationLoading.close();
          });
        });
      } else {
        console.log("获取地图失败");
        this.$nextTick(() => {
          this.locationLoading.close();
        });
      }
    },
    /**
     * 地图层点击
     */
    clickMapEvent(point) {
      if (this.fenceForm.shape == 1) {
        this.circlePath.center.lng = point.Bg.lng;
        this.circlePath.center.lat = point.Bg.lat;
        this.fenceForm.longitude = point.Bg.lng;
        this.fenceForm.latitude = point.Bg.lat;
      } else {
        this.inDrawLine = true;

        if (this.polygonPath.length > 0) {
          this.polygonPath.pop();
        }
        this.polygonPath.push({ lng: point.point.lng, lat: point.point.lat });
        this.polygonPath.push({ lng: point.point.lng, lat: point.point.lat });
      }
      return true;
    },
    updateCirclePath(e) {
      this.circlePath.center = e.target.getCenter();
      this.circlePath.radius = e.target.getRadius();
    },
    updatePolygonPath(e) {
      this.polygonPath = e.target.getPath();
    },
    back() {
      this.$router.back();
    },
    collapseChage() {
      this.extendBar = !this.extendBar;
      if (this.extendBar) {
        this.$refs.content.style.height = "300px";
      } else {
        this.$refs.content.style.height = 0;
      }
    },
    addTimeRange() {
      this.timeRangeList.push(this.timeRange);
    },
    handleRemoveTimeRange(index) {
      this.timeRangeList.splice(index, 1);
    },
    /**
     * 地图层移动
     * @param e
     */
    syncPolyline(e) {
      if (this.inDrawLine) {
        this.$set(this.polygonPath, this.polygonPath.length - 1, e.point);
      }
    },
    selectShape(v) {
      this.inDrawLine = false;
      if (v == 2) {
        this.mapInstance._map.setDefaultCursor("crosshair");
      } else {
        this.mapInstance._map.setDefaultCursor("pointer");
      }
    },
    newPolyline() {
      this.inDrawLine = false;
    },
    reDrawLine() {
      this.polygonPath = [];
    },
  },
};
</script>
<style scoped>
.fence_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fenceForm {
  margin-bottom: 10px;
}

.condion {
  flex: 1;
  /*background: #FFF;*/
  /*height: 300px;*/
  transition: all 0.1s ease-in 0s;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.cl_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 40px;
  justify-content: flex-start;
}

.cl_center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.inlineTitle {
  color: #606266;
  font-size: 14px;
}

.handle-input {
  width: 200px;
}

.handle-input_pos {
  width: 140px;
  margin-right: 10px;
}

.handle-select {
  width: 200px;
}

.searchBtn {
  width: 80px;
  height: 30px;
  background: #3b7bcf;
  margin-left: 20px;
}

.maplayer {
  flex: 1;
  position: relative;
}

.searchMap {
  position: absolute;
  top: 20px;
  right: 100px;
}

.map {
  width: 100%;
  height: 100%;
}

.bar {
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #3b7bcf;
  cursor: pointer;
}

.infoBoard {
  margin-top: 10px;
}

.required {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.required:after {
  content: "*";
  color: #f56c6c;
  font-weight: bold;
  margin-left: 4px;
  margin-right: 4px;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
}

.shapeInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.select_city {
  margin-left: 10px;
}

.fence_type_list {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 4px 4px 4px #8c939d;
}

.guide {
  font-weight: bold;
}

.reDrawBtn {
  width: 80px;
  background: #3b7bcf;
  margin-left: 20px;
}
</style>