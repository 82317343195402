<template>
  <rv-dialog :title="title" @close="close">
    <el-form
      ref="form"
      :model="userForm"
      :rules="userRules"
      label-width="80px"
      :inline="true"
    >
      <el-form-item label="密码" prop="password">
        <el-input
          type="password"
          v-model="userForm.password"
          placeholder="请填写密码"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="option-buttons">
      <el-button type="warning" icon="el-icon-plus" @click="addUser"
        >保存</el-button
      >
      <el-button type="primary" @click="close">取消</el-button>
    </div>
  </rv-dialog>
</template>

<script>
import { userEditPwd } from "@/api/system/user";
export default {
  data() {
    return {
      dialogState: true,
      title: "重置密码",
      userForm: {
        password: "",
        id: "",
      },
      userRules: {
        password: [{ required: true, message: "请填写密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    addUser() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          userEditPwd({
            password: this.userForm.password,
            id: this.userForm.id,
          }).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "重置成功",
                type: "success",
              });
              this.$emit("close", true);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    close() {
      this.$emit("close");
    },
    initData(e) {
      this.userForm.id = JSON.parse(JSON.stringify(e)).id;
    },
  },
};
</script>

<style scoped>
.option-buttons {
  display: flex;
  justify-content: center;
}
::v-deep .el-select {
  width: 177px;
}
</style>