<template>
  <div class="equip_wrap">
    <div class="search_cond" ref="search_cond">
      <el-input
        v-model="searchOption.orderSn"
        placeholder="订单编号"
        class="handle-input mr10 mt10"
      ></el-input>
      <el-select
        v-model="searchOption.status"
        class="handle-select mr10 mt10"
        placeholder="是否处理"
        clearable
      >
        <el-option key="0" label="未处理" value="0"></el-option>
        <el-option key="1" label="已处理" value="1"></el-option>
      </el-select>
      <el-button
        type="primary"
        icon="el-icon-search"
        @click="handleSearch"
        class="ml20 mt10 searchBtn"
        >搜索</el-button
      >
    </div>
    <div class="data_list">
      <el-table
        :data="tableData"
        highlight-current-row
        :style="'width: 100%;overflow-y:auto;margin-top:-10px'"
        :header-cell-style="{ background: '#FFF', color: '#212121' }"
      >
        <el-table-column
          type="index"
          label="序号"
          align="left"
          :index="indexMethod"
          width="50"
        />

        <el-table-column prop="orderNumber" label="订单号" align="left" />
        <el-table-column prop="oldcarNumber" label="录入车牌号" align="left">
        </el-table-column>
        <el-table-column prop="newcarNumber" label="接口车牌号" align="left">
        </el-table-column>
        <el-table-column prop="insertTime" label="冲突时间" align="left">
        </el-table-column>
        <el-table-column label="处理方式" align="left">
          <template slot-scope="scope">
            <div class="verticalCol">
              <span>{{ isOrderhandletype(scope.row.handletype) }}</span>
              <!-- <span>{{scope.row.expectReturnCarTime}}</span> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="left">
          <template slot-scope="scope">
            <div class="verticalCol">
              <span>{{ isOrderhandleStatus(scope.row.status) }}</span>
              <!-- <span>{{scope.row.expectReturnCarTime}}</span> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="left">
          <template slot-scope="scope">
            <el-button type="text" @click="deleteBind(scope.row.id, 'old')"
              >按录入</el-button
            >
            <el-button type="text" @click="deleteBind(scope.row.id, 'new')"
              >按接口</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        layout="prev, pager, next,total"
        :total="searchOption.totalCount"
        :page-size="10"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import order from "@/api/order";
import { Message } from "element-ui";
import {
  formatAlarmType,
  formatAlarm,
  isOrderhandletype,
  isOrderhandleStatus,
} from "@/api/orderview";
//import {formatFactory} from '../api/carview'//equipTypeFormat
export default {
  data() {
    return {
      bindEquipVisible: false,
      searchOption: {
        name: "",
        pageSize: "10",
        totalCount: 1,
        pageNum: 1,
        orderSn: "",
        vehicleSn: "",
        userName: "",
        warnType: "",
        city: "",
        clearStatus: "",
        orderStatus: "",
        cur_page: 1,
        autoload: "30",
        orderNum: "",
        status: "0",
      },
      tableData: [],
    };
  },
  comments: {},
  mounted() {
    this.loadData();
  },
  methods: {
    handleSearch() {
      this.loadData();
    },
    loadData() {
      let param = {
        pageNum: this.searchOption.pageNum,
        pageSize: this.searchOption.pageSize,
        orderNumber: this.searchOption.orderNum,
        status: this.searchOption.status,
      };
      order.getCarOrderClashList(param).then((res) => {
        if (res.code == 0) {
          this.tableData = res.result.list;
          this.searchOption.totalCount = res.result.total;
        }
      });
    },
    deleteBind(v, action) {
      order.saveCarOrderClash(v, action).then((res) => {
        if (res.code == 0) {
          Message.success({
            message: "处理成功",
          });
          this.loadData();
        } else {
          Message.error({
            message: res.msg,
          });
        }
      });
    },
    formatAlarmType(v) {
      return formatAlarmType(v);
    },
    isOrderhandletype(v) {
      return isOrderhandletype(v);
    },
    isOrderhandleStatus(v) {
      return isOrderhandleStatus(v);
    },
    formatAlarm(typeAlarm, warnName, fenceName, macid, entity_factory) {
      return formatAlarm(typeAlarm, warnName, fenceName, macid, entity_factory);
    },
    // deleteBind(number,facID){
    //   let param = {
    //     fenceId:this.fenceId,
    //     entityNumber:number,
    //     entityFactory:facID
    //   }
    //   equipApi.removeBind(param).then(res=>{
    //     if(res.code == 0) {
    //       this.$emit('success')
    //     }else{
    //       Message.error({
    //         message: res.msg,
    //       })
    //     }
    //   })
    // },
    handleCurrentChange(val) {
      this.searchOption.pageNum = val;
      this.loadData();
    },

    indexMethod(index) {
      let curPage = this.searchOption.pageNum;
      let pageSize = this.searchOption.pageSize;
      return index + 1 + (curPage - 1) * pageSize;
    },
  },
};
</script>
<style scoped>
.equip_wrap {
  display: flex;
  flex-direction: column;
}
.filter_list {
  display: flex;
  flex-direction: row;
}
.search_cond {
  height: 80px;
  padding-left: 20px;
}
.searchBtn {
  height: 30px;
  background: #3b7bcf;
}
.handle-input {
  width: 200px;
  display: inline-block;
}
.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #fff;
}
</style>
<style less>
.el-dialog__header {
  background: rgb(26, 36, 62);
  display: flex;
}
</style>