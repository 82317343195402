import {post} from './request'

export default {
    /**
     * 判断订单号是否存在
     * @returns {Promise | Promise<unknown>}
     */
    checkOrderNumber(no){
        return post('/nooperateorder/getNoOperateOrderByOrderNumber?orderNumber='+no)
    },
    // /**
    //  * 判断原订单号是否存在
    //  * @returns {Promise | Promise<unknown>}
    //  */
    // checkPreOrderNumber(no){
    //     return post('/carorder/getOrderByPreNumber?preNumber='+no)
    // },

    /**
     * 添加订单
     * @returns {Promise | Promise<unknown>}
     */
    add(params){
        return post('/nooperateorder/noOperateOrderAdd', params)
    },
    /**
         * 修改订单
         * @returns {Promise | Promise<unknown>}
         */
    edit(params){
        return post('/nooperateorder/noOperateOrderEdit', params)
    },

    /**
     * 监控中订单列表
     * @param param
     * @returns {Promise | Promise<unknown>}
     */
    getListInMonitor(param){
        return post('/nooperateorder/queryNoOperateOrderPage?pageNum='
            +param.pageNum+'&pageSize='+param.pageSize +'&orderNumber='+param.orderSn )
    },

    /**
     * 获取订单信息
     * @param param
     * @returns {Promise | Promise<unknown>}
     */
    getOrderContent(param){
        return post('/nooperateorder/queryNoOperateOrderyByOrderNumber?orderNumber='+param.sn )
    },

    /**
     * 订单列表
     * @param param
     * @returns {Promise | Promise<unknown>}
     */
    getListInMysql(param){
        return post('/nooperateorder/noOperateOrderList?',param )
    },

    // /**
    //  * 订单车辆冲突列表
    //  * @param param
    //  * @returns {Promise | Promise<unknown>}
    //  */
    // getCarOrderClashList(param){
    //     return post('/carorderclash/queryOrderClashPage?pageNum='
    //         +param.pageNum+'&pageSize='+param.pageSize +'&orderNumber='+param.orderNumber+'&status='+param.status )
    // },
    // /**
    //  * 保存车辆冲突
    //  * @param param
    //  * @returns {Promise | Promise<unknown>}
    //  */
    // saveCarOrderClash(id,action){
    //     return post('/carorderclash/clashHandle?id='
    //         +id+'&action='+action)
    // },
     /**
         * 修改订单备注
         * @returns {Promise | Promise<unknown>}
         */
      editRemark(params){
        return post('/nooperateorder/noOperateOrderEditRemark', params)
    },
     /**
         * 查看订单备注
         * @returns {Promise | Promise<unknown>}
         */
      ViewRemark(param){
        return post('/nooperateorder/getNoOperateOrderEditRemarkList?pageNum='
        +param.pageNum+'&pageSize='+param.pageSize +'&orderNumber='+param.orderNumber)
    },
}
