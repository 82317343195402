<template>
  <div class="data_wrap">
    <el-tabs v-model="currentView">
      <el-tab-pane label="运营订单" name="order"></el-tab-pane>
      <el-tab-pane label="非运营订单" name="proOrder"></el-tab-pane>
      <el-tab-pane label="在库车辆" name="inCar"></el-tab-pane>
    </el-tabs>
    <!--:is 的作用：会将div标签转换成 currentView 变量绑定的这个组件-->
    <div :is="currentView" keep-alive></div>
  </div>
</template>

<script>
export default {
  components: {
    //以下方式引入路由是路由的懒加载，有利于页面优化
    order: (resolve) => {
      require(["./components/VehicleStatusHistory.vue"], resolve);
    },
    proOrder: (resolve) => {
      require(["./components/VehicleStatusPreOrderHistory.vue"], resolve);
    },
    inCar: (resolve) => {
      require(["./components/VehicleStatusInCarHistory.vue"], resolve);
    },
  },
  data() {
    return {
      currentView: "order", //当前组件
    };
  },
  methods: {},
  created() {},
};
</script>
<style scoped>
.data_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>